<script>
import { dashboard } from "../../../helpers/api/api";

/**
 * Widget Component
 */
export default {
  data() {
    return {
      statData: [],
    };
  },
  created() {
    this.getDashboard();
  },
  methods: {
    getDashboard() {
      dashboard().then((res) => {
        this.statData = [
              {
                title: "Gross Sales",
                icon: "ri-stack-line",
                value: "$ " + res.data.grossSale,
                subvalue: " 19% ",
              },
              {
                title: "Earnings",
                icon: "ri-store-2-line",
                value: "$ " + res.data.earnings,
                subvalue: " 25% ",
              },
              {
                title: "Sold Item",
                icon: "ri-user-fill",
                value: res.data.soldItem,
                subvalue: " 20% ",
              },
              {
                title: "Order Recieved",
                icon: "ri-briefcase-4-line",
                value: res.data.orderRecieved,
                subvalue: " 20% ",
              },
            ];
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
<template>
  <div class="material">
    <div @click="handleOpen()">
      <slot name="custom-open-button">
        <el-button class="upload-btn">{{
          $t("productManagement.uploadClick")
        }}</el-button>
      </slot>
    </div>
    <el-dialog
      width="1024px"
      :center="true"
      :visible="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <el-container class="dialog-container">
        <!-- 顶部 -->
        <el-header height="120px">
          <UploadImage
            v-if="type === 'image'"
            @onsuccess="handleImageUploadSuccess"
          />
          <!-- <UploadVideo
            v-else-if="type === 'video'"
            @onsuccess="handleVideoUploadSuccess"
          /> -->
        </el-header>
        <material-list
          ref="list"
          :type="type"
          :count="count"
          @onCheck="handleCheck"
        />
      </el-container>
      <template v-slot:footer>
        <el-button
          class="opt-btn"
          type="text"
          @click="navto('/material/list')"
          >{{ $t("menu.material") }}</el-button
        >
        <el-button
          class="btn"
          :disabled="disabledSubmit"
          @click="handleSubmit"
          >{{ $t("common.ok") }}</el-button
        >
        <el-button class="btn" @click="handleClose">{{
          $t("common.cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import UploadImage from "@/components/UploadImage";
// import UploadVideo from "@/components/UploadVideo";
import MaterialList from "@/components/MaterialList";
export default {
  components: {
    UploadImage,
    // UploadVideo,
    MaterialList,
  },
  props: {
    type: {
      require: true,
      type: String,
    },
    count: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogVisible: false,
      iCheckMaterialList: [],
    };
  },
  computed: {
    disabledSubmit: function () {
      return !(this.iCheckMaterialList.length > 0);
    },
  },
  methods: {
    handleCheck(list) {
      this.iCheckMaterialList = list;
    },
    handleImageUploadSuccess(e) {
      console.log("handleImageUploadSuccess:", e);
      this.$refs["list"].getList(1);
    },
    handleVideoUploadSuccess(e) {
      console.log("handleVideoUploadSuccess:", e);
      this.$refs["list"].getList(1);
    },
    // 打开弹窗
    handleOpen() {
      this.iCheckMaterialList = [];
      this.dialogVisible = true;
      this.$refs["list"] && this.$refs["list"].getList();
    },
    // 关闭弹窗
    handleClose() {
      this.$refs["list"].cleanList();
      this.dialogVisible = false;
    },
    // 提交数据
    handleSubmit() {
      console.log("handleSubmit:", this.iCheckMaterialList);
      this.$emit(
        "receiver",
        this.count === 1 ? this.iCheckMaterialList[0] : this.iCheckMaterialList
      );
      this.handleClose();
    },
    navto(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss">
.dialog-container {
  height: 660px;
}
.upload-oss {
  display: block;
}
.material .upload-oss .el-upload-dragger {
  width: 100%;
  height: 120px;
  margin: 0;
}
.upload-oss .el-upload {
  width: 100%;
  height: 120px;
}
.upload-btn {
  width: 150px;
  height: 50px;
  border-radius: 6px;
  background: #ff0a0a;
  color: #fff;
}
.opt-btn {
  position: absolute;
  left: 50px;
  bottom: 30px;
}
.el-button.is-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border-color: #ebeef5;
}
</style>

import store from '@/state/store'

export default [
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('../views/pages/account/login'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters['auth/loggedIn']) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'home' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },

    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    // 20220620 start
    // 登录
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/Login/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    // 商家列表
    {
        path: "/vendors/store",
        name: "VendorList",
        component: () => import("@/views/pages/vendors/list")
    },
    // 商家列表详情
    {
        path: "/vendors/detail",
        name: "VendorDetail",
        component: () => import("@/views/pages/vendors/detail")
    },
    // 会员列表
    {
        path: "/users/list",
        name: "UsersList",
        component: () => import("@/views/pages/users/list")
    },
    {
        path:"/users/userdetail",
        name:"UserDetail",
        component:()=>import("@/views/pages/users/detail")
    },
    // 店铺列表
    {
        path: "/vendors/storeList",
        name: "VendorsStoreList",
        component: () => import("@/views/pages/vendors/storeList")
    },

    //20211020 start
    {
        path: '/blog/index',
        name: 'Blog',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/blog/index')
    },
    {
        path: '/blog/category',
        name: 'Blog Category',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/blog/category')
    },
    {
        path: '/users/index',
        name: 'Users',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/users/index')
    },
    {
        path: '/vendors/index',
        name: 'Vendors',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/vendors/index')
    },
    {
        path: '/vendors/store',
        name: "Store",
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/vendors/store')
    },
    {
        path: '/vendors/store/add',
        name: "StoreAdd",
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/vendors/storeAdd')
    },
    {
        path: '/vendors/store/PromoManagement',
        name: "PromoManagement",
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/vendors/PromoManagement')
    },
    {
        path: '/vendors/storeCert',
        name: "Store Cert",
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/vendors/storeCert')
    },
    {
        path: '/extend/gallery',
        name: 'Gallery',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/extend/gallery')
    },
    {
        path: '/subscriptions/index',
        name: 'Subscriptions',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/subscriptions/index')
    },
    {
        path: '/ecommerce/category',
        name: 'Category',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/ecommerce/category')
    },
    {
        path: '/extend/slider',
        name: 'Slider',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/extend/slider')
    },
    {
        path: '/extend/featured-products',
        name: 'Featured Products',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/extend/featured-products')
    },
    {
        path: '/inquiry/inquiry',
        name: 'Inquiry',
        meta: {
            authRequired: true
        },
        component: () => import('../views/pages/inquiry/inquiry')
    },

    //20211030 end

    {
        path: '/chat',
        name: 'Chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/ecommerce/products',
        name: 'Products',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/products')
    },
    {
        path: '/ecommerce/orders',
        name: 'Orders',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/orders')
    },
    {
        path: '/ecommerce/brand',
        name: 'Brand',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/brand')
    },
    {
        path: '/extend/home-page',
        name: 'Home Page',
        meta: { authRequired: true },
        component: () => import('../views/pages/extend/home-page')
    },
    {
        path: '/auth/admin',
        name: 'Admin',
        meta: { authRequired: true },
        component: () => import('../views/pages/auth/admin')
    },

    // new -> new?
    {
        path: '/order/list',
        name: 'OrderList',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/order/list')
    },
    {
        path: '/order/detail',
        name: 'OrderDetail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/order/detail')
    },

    {
        path: '/exchange_rate/list',
        name: 'Exchange_rateList',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/exchange_rate/list')
    },
]

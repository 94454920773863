<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists, getData, updateData, setStatus} from "../../../helpers/api/api";

export default {
  page: {
      title: "Users",
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.users.text'),
      items: [
        {
          text: this.$t('content.users.text')
        },
        {
          text: this.$t('content.users.lists'),
          active: true
        }
      ],
      usersData: [],
      submitted: false,
      showmodal: false,
      editUserModel: false,
      startIndex: 0,
      endIndex: 0,
      rows: 100,
      currentPage: 1,
      perPage: 10,
      id: '',
      name: '',
      email: '',
      mobile: '',
      country: '',
      status: ''
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    mobile: { required, minLength: minLength(10) },
    country: {required}
  },
  created() {

     this.userLists(this.currentPage); 
  },
  methods: {

    userLists(page) {
        getLists('GET', 'user', page, 'desc').then(res => {
            this.usersData = [];
            res.data.rows.forEach(item => {
                item.status = item.status == 'Active' ? true : false;
                this.usersData.push(item);
            })
            this.rows = res.data.count
        });
    },

    setUserStatus(id, status) {
        var set_status = status == true ? 0 : 1;
        setStatus('PUT', 'user', id, set_status).then();
    },
    
    editUser(id) {
        this.editUserModel = true;
        
         getData('GET', 'user', id).then(res => {
            this.id = id
            this.name = res.data.name
            this.email = res.data.email
            this.mobile = res.data.mobile
            this.country = res.data.country
            this.status = res.data.status
        });
    },

    deleteUser(id) {
        
        this.$swal({
            text: this.$t('common.confirmdelete'),
            confirmButtonText: this.$t('common.ok'),
            cancelButtonText: this.$t('common.cancel'),
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed == true) {

                setStatus('GET', 'user', id, 2).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.userLists(this.currentPage - 1);
                });

            }
        }); 
        
    },

    onPageChange() {
        this.startIndex = (this.currentPage - 1) * this.perPage;
        this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
        this.userLists(this.currentPage);
    },

    submitData() {
        this.$v.$touch()
        if (this.$v.$invalid) {
              
            this.$swal({
                text: this.$t('content.subscriptions.submitError'),
                confirmButtonText: this.$t('common.ok')
            });

        } else {
          
            let data = {
                name: this.name,
                email: this.email,
                mobile: this.mobile,
                country: this.country
            };

            updateData('PUT', 'user', this.id, data).then(res => {
                this.editUserModel = false;
                this.$swal({
                      text: res.message,
                      confirmButtonText: this.$t('common.ok')
                });

                this.userLists(this.currentPage - 1);
            });
        }
        
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>{{$t('common.id')}}</th>
                    <th>{{$t('common.name')}}</th>
                    <th>{{$t('common.email')}}</th>
                    <th>{{$t('common.mobile')}}</th>
                    <th>{{$t('common.country')}}</th>
                    <th>{{$t('common.status')}}</th>
                    <th>{{$t('common.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in usersData" :key="index">
                    <td>{{item._id}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.mobile}}</td>
                    <td>{{item.country}}</td>
                    <td>

                        <b-form-checkbox v-model="item.status" @change="setUserStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>

                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editUser(item._id)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteUser(item._id)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>


              <div class="row justify-content-md-between align-items-md-center">
                <div class="col-xl-7">{{$t("common.showing")}} {{startIndex}} - {{endIndex}} of {{rows}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal v-model="editUserModel" :title="$t('content.users.edituser')" centered>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('common.name')" v-model.trim="$v.name.$model" />
          <span class="error" v-if="!$v.name.required">* {{$t('common.namerequired')}}</span>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('common.email')" v-model.trim="$v.email.$model" />
          <span class="error" v-if="!$v.email.required">* {{$t('common.emailrequired')}}</span>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('common.mobile')" v-model.trim="$v.mobile.$model" />
          <span class="error" v-if="!$v.mobile.required">* {{$t('common.mobilerequired')}}</span>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('common.country')" v-model.trim="$v.country.$model" />
          <span class="error" v-if="!$v.country.required">* {{$t('common.countryrequired')}}</span>
        </div>
        <input type="hidden" v-model="id" />
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editUserModel = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData">
          {{$t('common.submit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>




  </Layout>
</template>
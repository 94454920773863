<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists, getData, createData, updateData, setStatus} from "../../../helpers/api/api";
import { required} from 'vuelidate/lib/validators';
const moment = require('moment')

export default {
  page: {
      title: 'Brand'
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.brand.text'),
      items: [
        {
          text: this.$t('content.brand.text')
        },
        {
          text: this.$t('content.brand.lists'),
          active: true
        }
      ],
      brandData: [],
      submitted: false,
      editModal: false,
      formTitle: '',
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      brand_id: '',
      brandName: '',
      image: ''
    };
  },
  validations: {
      brandName: {required}
  },
  created() {
     this.brandLists(this.currentPage)
  },
  methods: {

    brandLists(page) {
        getLists('GET', 'brand', page, 'desc').then(res => {
            this.brandData = [];
            res.data.rows.forEach(item => {
                item.status = item.status == 'Active' ? true : false;
                item.updatedAt = moment(item.updatedAt).format('MMMM,DD YYYY HH:mm:ss');
                this.brandData.push(item);
            })
            this.totalRows = res.data.count;
        })
    },
    onPageChange() {
        this.brandLists(this.currentPage);
    },
   
    createBrand() {
        this.editModal = true;
        this.formTitle = this.$t('content.brand.add');
        this.editType = 'add';

        this.brandName = '';
    },

    editBrand(id) {
        this.editModal = true;
        this.formTitle = this.$t('content.brand.edit');
        this.editType = 'edit';
        this.brand_id = id;

        getData('GET', 'brand', id).then(res => {
            this.brandName = res.data.brandName;
        });
    },
    fileChange() {
        var that = this;
        var files = document.getElementById("image").files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            that.image = reader.result;
        };
        reader.readAsDataURL(files);
    },
    setBrandStatus(id, status) {
        var set_status = status == true ? 0 : 1;
        setStatus('PUT', 'brand', id, set_status).then();
    },

    deleteBrand(id) {
        this.$swal({
            text: this.$t('common.confirmdelete'),
            confirmButtonText: this.$t('common.ok'),
            cancelButtonText: this.$t('common.cancel'),
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed == true) {

                setStatus('PUT', 'brand', id, 2).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.brandLists(this.currentPage);
                });

            }
        }); 
    },
    
    submitData() {
        
        this.editModel = false;

        this.$v.$touch()
        if (this.$v.$invalid) {
              
            this.$swal({
                text: this.$t('common.submitError'),
                confirmButtonText: this.$t('common.ok')
            });

        } else {

            this.image = this.image.replace(/^data:image\/\w+;base64,/, "");

            if (this.editType == 'add') {

                createData('POST', 'brand', {
                    brandName: this.brandName,
                    img: this.image
                }).then(res => {

                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.brandLists(this.currentPage);
                });
            } else {

                updateData('PUT', 'brand', this.brand_id, {
                    brandName: this.brandName,
                    img: this.image
                }).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.brandLists(this.currentPage);
                });

            }
            
        } 

    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">

                <div class="row">
                            <div class="col-md-6">
                            <div class="product-title float-left">
                                <button class="btn-add" @click="createBrand()"><i class="ri-add-line"></i>{{  $t('content.brand.add') }}</button>
                            </div>
                            </div>

                            <div class="col-md-6">
                            <div class="form-inline float-md-right">
                                <div class="search-box ml-2">
                                <div class="position-relative">
                                    <input type="text" class="form-control rounded" :placeholder="$t('common.search')" />
                                    <i class="mdi mdi-magnify search-icon"></i>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                    <table
                        class="table table-centered datatable dt-responsive nowrap"
                        style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                    >
                        <thead class="thead-light">
                        <tr>
                            <th>{{$t('common.id')}}</th>
                            <th>{{$t('common.name')}}</th>
                            <th>{{$t('common.slug')}}</th>
                            <th>{{$t('common.status')}}</th>
                            <th>{{$t('common.updatetime')}}</th>
                            <th>{{$t('common.action')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in brandData" :key="index">
                            <td>{{item._id}}</td>
                            <td>{{item.brandName}}</td>
                            <td>{{item.slug}}</td>
                            <td>
                                <b-form-checkbox v-model="item.status" @change="setBrandStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>
                            </td>
                            <td>{{item.updatedAt}}</td>
                            <td>

                            <a
                                href="javascript:void(0);"
                                class="mr-3 text-primary"
                                v-b-tooltip.hover
                                :title="$t('common.edit')"
                                @click="editBrand(item._id)"
                            >
                                <i class="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a
                                href="javascript:void(0);"
                                class="text-danger"
                                v-b-tooltip.hover
                                :title="$t('common.delete')"
                                @click="deleteBrand(item._id)"
                            >
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </div>

                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="onPageChange"></b-pagination>
                        </ul>
                        </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal v-model="editModal" :title="formTitle" centered>
      <form>
          <div class="form-group">
            <input type="text" class="form-control" :placeholder="$t('common.name')" v-model.trim="$v.brandName.$model" />
            <span class="error" v-if="!$v.brandName.required">* {{$t('common.namerequired')}}</span>
           </div>

            <div class="form-group row-box">
                <input type="file" id="image" accept="image/*" class="form-control" :placeholder="$t('content.gallery.url')" @change="fileChange" />
            </div>
        
      </form>
      
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editModal = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData">
          {{$t('common.submit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

  </Layout>
</template>
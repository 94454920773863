import request from './axios';

let admin_user_token = localStorage.getItem('admin_user_token')
let headers = {'x-auth-token': admin_user_token, 'Content-Type': 'application/json'};

export const dashboard = () => {
    return request({
       url: 'admin/dashboard/all',
       method: 'get',
       headers: headers 
    });
}

export const getCountry = () => {
    return request({
       url: 'country',
       method: 'get',
       headers: headers 
    });
}

export const getStoreList = () => {
    return request({
       url: 'admin/store?orderDir=desc&pageSize=9999',
       method: 'get',
       headers: headers 
    });
}

export const searchLists = (method, module, params = '', pageNumber = 0, orderDir = 'desc') => {
    let url = '?orderDir='+ orderDir;
    if (pageNumber > 0)
        url += '&pageNumber='+ pageNumber;
    if (params) 
        url += '&'+ params;

    return request({
        url: 'admin/'+ module + url,
        method: method,
        headers: headers
    });
}

//获取订单所有信息
export const getLists = (method, module, pageNumber = 0, orderDir = 'desc') => {
    let url = '?orderDir='+ orderDir;
    if (pageNumber > 0)
        url += '&pageNumber='+ pageNumber;

    return request({
        url: 'admin/'+ module + url,
        method: method,
        headers: headers
    });
}

//获取产品管理所有信息
export const getProductLists = (method, module, pageNumber = 0, productDir = 'desc') => {
    let url = '?productDir='+ productDir;
    if (pageNumber > 0)
        url += '&pageNumber='+ pageNumber;

    return request({
        url: 'admin/'+ module + url,
        method: method,
        headers: headers
    });
}

export const getData = (method, module, id) => {
    return request({
        url: 'admin/'+ module +'/'+ id,
        method: method,
        headers: headers
    });
}
export const createData = (method, module, data) => {
    return request({
        url: 'admin/'+ module,
        method: method,
        headers: headers,
        data: data
    });
}
export const updateData = (method, module, id, data) => {
    return request({
        url: 'admin/'+ module +'/'+ id,
        method: method,
        headers: headers,
        data: data
    });
}
export const setStatus = (method, module, id, status) => {
    return request({
        url: 'admin/'+ module +'/update-status/'+ id +'/'+ status,
        method: method,
        headers: headers
    });
}

export const editStatus = (method, module, id, status, data) => {
    return request({
        url: 'admin/'+ module +'/update-status/'+ id +'/'+ status,
        method: method,
        headers: headers,
        data: data
    });
}

export const deleteData = (method, module, id) => {
    return request({
        url: 'admin/'+ module +'/'+ id,
        method: method,
        headers: headers
    });
}

export const login = (email, password) => {
    return request({
        url: 'auth/admin',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: {"email": email, "password": password}
    });
}

export const getSetting = (method, module) => {
    return request({
        url: 'admin/'+ module,
        method: method,
        headers: headers 
    });
}

export const updateSetting = (method, module, data) => {
    return request({
        url: "admin/"+ module,
        method: method,
        headers: headers,
        data: data
    });
}

export const uploadoss = (method, module, data) => {
    let form = new FormData();
    console.log(data)
    form.append('key', data.key);
    form.append('OSSAccessKeyId', data.OSSAccessKeyId);
    form.append('policy', data.policy);
    form.append('signature', data.signature);
    form.append('success_action_status', data.success_action_status);
    form.append('file', data.file);
    return request({
        url: module,
        method: method,
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: form
    });
}

export const renderData = (method, module) => {
    return request({
        url: 'admin/' + module,
        method: method,
        headers: headers,
    })
}
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists, getData, setStatus, createData, updateData} from "../../../helpers/api/api";
import { required} from 'vuelidate/lib/validators';

export default {
  page: {
      title: 'Category'
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.blog.category'),
      items: [
        {
          text: this.$t('content.blog.category')
        },
        {
          text: this.$t('content.category.lists'),
          active: true
        }
      ],
      categoryData: [],
      submitted: false,
      showmodal: false,
      editCategoryModel: false,
      parentCategoryData: [],
      categoryName: '',
      selected: 'selected',
      formTitle: '',
      editType: 'add',
      category_id: '',
      status: ''
    };
  },
  validations: {
      categoryName: {required}
  },
  created() {
     this.categoryLists(); 
  },
  methods: {

    categoryLists() {
        getLists('GET', 'blogcategory').then(res => {
            this.categoryData = [];
            res.data.forEach(item => {
                item.status = item.status == 'Active' ? true : false;
                this.categoryData.push(item);
            })
        });
    },

    createCategory() {
        this.editCategoryModel = true;
        this.formTitle = this.$t('content.category.addnewcategory');
        this.editType = 'add';

        this.categoryName = '';
    },

    editCategory(id) {
        this.editCategoryModel = true;
        this.formTitle = this.$t('content.category.editcategory');
        this.editType = 'edit';
        this.category_id = id;

        getData('GET', 'blogcategory', id).then(res => {
            this.categoryName = res.data.categoryName
        });
    },
   
    deleteCategory(id) {
        
        this.$swal({
            text: this.$t('common.confirmdelete'),
            confirmButtonText: this.$t('common.ok'),
            cancelButtonText: this.$t('common.cancel'),
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed == true) {

                setStatus('GET', 'blogcategory', id, 2).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.categoryLists();
                });

            }
        }); 
        
    },

    setCategoryStatus(id, status) {
        var set_status = status == true ? 0 : 1;
        setStatus('GET', 'blogcategory', id, set_status).then();
    },

    submitData() {
        
        this.editCategoryModel = false;
        this.$v.$touch()
        if (this.$v.$invalid) {
              
            this.$swal({
                text: this.$t('common.submitError'),
                confirmButtonText: this.$t('common.ok')
            });

        } else {

            if (this.editType == 'add') {

                createData('POST', 'blogcategory', {
                    categoryName: this.categoryName
                }).then(res => {

                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.categoryLists();
                });
            } else {

                updateData('PUT', 'blogcategory', this.category_id, {
                    categoryName: this.categoryName
                }).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                      this.categoryLists();
                });

            }
            
        } 

    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">

                <div class="row">
                    <div class="col-md-6">
                    <div class="product-title float-left">
                        <button class="btn-add" @click="createCategory('')"><i class="ri-add-line"></i>{{  $t('content.category.addnewcategory') }}</button>
                    </div>
                    </div>

                    <div class="col-md-6">
                    <div class="form-inline float-md-right">
                        <div class="search-box ml-2">
                        <div class="position-relative">
                            <input type="text" class="form-control rounded" :placeholder="$t('common.search')" />
                            <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th width="250">{{$t('common.id')}}</th>
                    <th>{{$t('common.name')}}</th>
                    <th width="100">{{$t('common.status')}}</th>
                    <th width="120">{{$t('common.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in categoryData" :key="index">
                    <td>{{item._id}}</td>
                    <td>{{item.categoryName}}</td>
                    <td>

                      <b-form-checkbox v-model="item.status" @change="setCategoryStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>

                    </td>
                    <td>

                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editCategory(item._id)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteCategory(item._id)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal v-model="editCategoryModel" :title="formTitle" centered>
      <form>
          <div class="form-group">
            <input type="text" class="form-control" :placeholder="$t('common.name')" v-model.trim="$v.categoryName.$model" />
            <span class="error" v-if="!$v.categoryName.required">* {{$t('common.namerequired')}}</span>
           </div>
      </form>
      
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editCategoryModel = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData">
          {{$t('common.submit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

  </Layout>
</template>
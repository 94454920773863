<template>
  <el-form
    :model="data"
    status-icon
    :rules="rules"
    label-position="top"
    :validate-on-rule-change="false"
    ref="ruleForm"
    v-loading="loading"
  >
    <template v-for="(item, index) in list">
      <el-divider
        v-if="item.type === 'title'"
        content-position="left"
        :key="`key_${index}`"
        >{{ $t(item.label) }}</el-divider
      >
      <el-form-item
        v-else
        v-show="!hiddenNameList.includes(item.name)"
        :prop="item.name"
        :label="$t(item.label)"
        :key="`key_${index}`"
      >
        <template v-if="item.type === 'mobile'">
          <el-col :span="9">
            <el-select v-model="data.phoneCode">
              <el-option
                v-for="item in phoneCodeJson"
                :key="item.id"
                :label="
                  /*$t('categorys.' + item.nicename)很多国家名称没对称，延后处理*/ item.nicename +
                  ' +' +
                  item.phonecode
                "
                :value="item.phonecode"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="14">
            <el-input
              v-model="data[item.name]"
              autocomplete="autocomplete"
              style="margin-left: 10px"
            ></el-input>
          </el-col>
        </template>
        <template v-else-if="item.type === 'uploadimage'">
          <upload-image-list v-model="data[item.name]" :item="item" />
        </template>
        <template v-else-if="item.type === 'radio'">
          <el-radio-group
            v-model="data[item.name]"
            @change="onChange($event, item.setHidden)"
          >
            <el-radio
              v-for="(rangeItem, rangeIndex) in item.range"
              :key="`rangeIndex-${rangeIndex}`"
              :label="rangeItem.value"
            >
              <i
                v-if="rangeItem.icon"
                class="sokogate"
                :class="rangeItem.icon"
              />
              {{ $t(rangeItem.label) }}
            </el-radio>
          </el-radio-group>
        </template>
        <template v-else-if="item.type === 'select'">
          <el-col>
            <el-select
              v-model="data[item.name]"
              :placeholder="$t('common.selectPlaceholder')"
              autocomplete="autocomplete"
            >
              <el-option
                v-for="rangeItem in item.range"
                :key="rangeItem.value"
                :label="$t(rangeItem.label)"
                :value="rangeItem.value"
                >{{ $t(rangeItem.label) }}</el-option
              >
            </el-select>
          </el-col>
        </template>
        <template v-else-if="item.type === 'switch'">
          <el-col>
            <el-switch
              v-model="data[item.name]"
              :active-value="1"
              :inactive-value="0"
              @change="onChange($event, item.setHidden)"
            >
            </el-switch>
          </el-col>
        </template>
        <template v-else-if="!!components[item.type]">
          <component
            v-bind:is="components[item.type]"
            v-model="data[item.name]"
          ></component>
        </template>
        <el-col v-else>
          <el-input
            v-if="item.type === 'password'"
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model.trim="data[item.name]"
            autocomplete="autocomplete"
            :show-password="true"
          ></el-input>
          <el-input
            v-else-if="item.type === 'mail'"
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model.trim="data[item.name]"
            autocomplete="autocomplete"
          ></el-input>
          <el-input
            v-else-if="item.type === 'number'"
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model.number="data[item.name]"
            autocomplete="autocomplete"
          ></el-input>
          <el-input
            v-else
            :type="item.type"
            :placeholder="$t(item.label)"
            v-model="data[item.name]"
            autocomplete="autocomplete"
          ></el-input>
        </el-col>
      </el-form-item>
    </template>
    <el-form-item class="form-item-flex">
      <el-button type="primary" @click="submitForm('ruleForm')">
        {{ $t(submitTitle) }}
      </el-button>
      <el-button v-if="showReset" @click="resetAndSubmit('ruleForm')">
        {{ $t("common.reset") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import phoneCodeJson from "../../../../static/phonecode.json";
import { metadata, PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();
import UploadImageList from "@/components/s-ui/form/UploadImageList";
import CountrySelect from "@/components/s-ui/form/CountrySelect";
import CategoryCascader from "@/components/s-ui/form/CategoryCascader";
import StoreSelect from "@/components/s-ui/form/StoreSelect";
import CategorySelect from "@/components/s-ui/form/CategorySelect";

export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultdata: {
      type: Object,
      default() {
        return {};
      },
    },
    submitTitle: {
      type: String,
      default: "common.submit",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadImageList,
    CountrySelect,
    CategoryCascader,
    StoreSelect,
    CategorySelect,
  },
  data() {
    return {
      phoneCodeJson: [],
      category: [],
      components: {
        country: CountrySelect,
        category: CategoryCascader,
        store: StoreSelect,
        categoryselect: CategorySelect,
      },
      data: this.defaultdata,
      customHiddenNameList: [],
    };
  },
  created() {
    this.phoneCodeJson = phoneCodeJson;
    document.onkeydown = (e) => {
      e = window.event || e;
      // 键盘的回车键进入
      if (e.code == "Enter" || e.code == "enter") {
        this.submitForm();
      }
    };
  },
  computed: {
    hiddenNameList() {
      const propsHiddenNameList = this.list
        .filter((v) => v.hidden)
        .map((v) => v.name);
      // console.log("propsHiddenNameList:", propsHiddenNameList);
      return [...propsHiddenNameList, ...this.customHiddenNameList];
    },
    rules() {
      // 验证手机正则
      // eslint-disable-next-line no-unused-vars
      var validateGlobalMobile = (rule, value, callback) => {
        if (value.length < 3) {
          callback(new Error());
        }
        const number = phoneUtil.parseAndKeepRawInput(
          value,
          metadata.countryCodeToRegionCodeMap[this.data.phoneCode][0]
        );
        const isValite = phoneUtil.isValidNumber(number);
        if (isValite) {
          callback();
        } else {
          callback(new Error());
        }
      };
      // 输入密码验证
      // eslint-disable-next-line no-unused-vars
      var validatePass = (rule, value, callback) => {
        if (this.data.confirmpassword !== "") {
          this.$refs.ruleForm.validateField("confirmpassword");
        }
        callback();
      };
      // 两次输入密码验证
      // eslint-disable-next-line no-unused-vars
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error());
        } else if (value !== this.data.password) {
          callback(new Error());
        } else {
          callback();
        }
      };
      return this.list
        .filter((v) => v.rules && !this.hiddenNameList.includes(v.name))
        .reduce(
          (a, v) => ({
            ...a,
            [v.name]: v.rules.map((r) => {
              const validator = r.eval && eval(r.eval);
              return {
                ...r,
                validator,
                message: r.message
                  ? this.$t(r.message)
                  : `${this.$t(v.label)}${this.$t("common.isreq")}`,
                trigger: Object.prototype.hasOwnProperty.call(r, "trigger")
                  ? r.trigger
                  : "blur",
              };
            }),
          }),
          {}
        );
    },
    showReset() {
      return !!this.$listeners["reset"];
    },
  },
  methods: {
    onUploadSuccess(value, item) {
      console.log("onUploadSuccess:", item, value);
      if (item.max) {
        const newValue = [...(this.data[item.name] || []), value.filename];
        console.log("newValue:", newValue);
        this.$set(this.data, item.name, newValue);
      } else {
        this.$set(this.data, item.name, value.filename);
      }
    },

    tapUploadDelete(name, item, index) {
      if (item.max) {
        const newValue = this.data[item.name];
        newValue.splice(index, 1);
        console.log("newValue:", newValue);
        this.$set(this.data, item.name, newValue);
      } else {
        this.$set(this.data, name, "");
      }
    },

    onChange(value, m) {
      if (!this.$utils.isEmpty(value) && m) {
        const [name, targetValue, action] = m;
        if (targetValue !== value && !action) {
          this.customHiddenNameList = [...this.customHiddenNameList, name];
        } else {
          this.customHiddenNameList = this.customHiddenNameList.filter(
            (val) => val !== name
          );
        }
      }
      // if (value && m) {
      //   const [name, targetValue, action] = m;
      //   const nameItem = this.list.find((v) => v.name === name);
      //   console.log("nameItem:", nameItem);
      //   const nameItemIndex = this.list.indexOf(nameItem);
      //   console.log("nameItemIndex:", nameItemIndex);
      //   this.$set(this.list, nameItemIndex, {
      //     ...this.list[nameItemIndex],
      //     hidden: targetValue === value ? !!action : !action,
      //   });
      // }
    },

    submitForm() {
      // console.log(this.data);
      this.$refs["ruleForm"].validate((valid, Object) => {
        console.log("Object:", Object);
        if (valid) {
          this.$emit("submit", this.data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    setForm(data) {
      console.log("setForm:", data);
      var newData = JSON.parse(JSON.stringify(data));
      this.data = newData;
    },

    resetFields() {
      this.$refs.ruleForm.resetFields();
    },

    resetAndSubmit(formName) {
      this.$refs[formName].resetFields();
      this.$emit("submit", this.data);
    },
  },
};
</script>

<style lang="scss">
.el-form--label-top .el-form-item__label {
  padding: 0;
  margin: 0;
}
.el-select {
  display: block;
}
.form-item-flex {
  display: block;

  .el-form-item__content {
    display: flex;

    .el-button {
      flex-grow: 1;
    }
  }
}
</style>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  page: {
      title: "Store Cert",
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.vendors.store'),
      items: [
        {
          text: this.$t('content.vendors.store')
        },
        {
          text: this.$t('content.vendors.storeCert'),
          active: true
        }
      ],
     
    };
  },
  validations: {
    
  },
  computed: {

  },
  created() {
      
  },
  methods: {
     
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
  </Layout>
</template>
<template>
  <el-card class="page-card" v-loading="loading">
    <slot name="header">
      <el-button type="primary" @click="add" v-if="max === 0 || count < max"
        >{{ $t("common.add") }} {{ $t(name) }}</el-button
      >
      <el-descriptions v-else :title="$t(name)"></el-descriptions>
    </slot>
    <template v-if="recomm.spuList && recomm.spuList.length">
      <sui-list
        ref="list"
        :column="TableColumn"
        :defaultData="recomm.spuList"
        @del="del"
        @moveup="up"
        @movedown="down"
      />
    </template>
    <el-dialog
      :title="`${$t('common.add')} ${$t(name)}`"
      :visible="editing"
      width="1000px"
      close-on-click-modal
      close-on-press-escape
      @close="close"
    >
      <sui-form
        ref="form"
        :list="formList"
        :defaultdata="formData"
        submitTitle="common.search"
        @submit="search"
        @reset="search"
      />
      <sui-list
        ref="dialogList"
        :column="dialogTableColumn"
        :fun="dialogTableFun"
        :req="dialogTableFunReq"
        :selection="true"
        @select="select"
        maxHeight="500"
      />
      <slot name="footer">
        <div class="dialog-footer">
          <el-button @click="close">{{ $t("common.cancel") }}</el-button>
          <el-button
            :disabled="!productIdsTemp.length"
            type="primary"
            @click="submit"
          >
            {{ $t("common.ok") }}
          </el-button>
        </div>
      </slot>
    </el-dialog>
  </el-card>
</template>
<script>
import SuiForm from "@/components/s-ui/form";
import SuiList from "@/components/s-ui/list";
// import { searchLists } from "@/helpers/api/api";
import {
  GetProductList,
  AddRecomm,
  EditRecomm,
  GetRecommList,
} from "@/utils/api";
const defaultFormData = {
  categoryIds: [],
  storeId: "",
  productName: "",
};
export default {
  props: {
    type: {
      type: Number,
      value: 0,
    },
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiForm, SuiList },
  data() {
    return {
      loading: false,
      formList: [
        {
          type: "category",
          name: "categoryIds",
          label: "common.category",
        },
        {
          type: "store",
          name: "storeId",
          label: "content.vendors.storeName",
        },
        {
          type: "input",
          name: "productName",
          label: "content.products.name",
        },
      ],
      formData: {
        categoryIds: [],
        storeId: "",
        productName: "",
      },
      editing: false,
      count: 0,
      dialogTableColumn: [
        {
          type: "image",
          name: "gallery",
          label: "content.products.productImages",
        },
        {
          type: "link",
          name: "name",
          label: "content.products.name",
          detail: {
            link: "https://sokogate.com/product-detail/",
            key: "productId",
          },
        },
        {
          name: "price",
          label: "content.products.price",
        },
      ],
      TableColumn: [
        {
          type: "image",
          name: "img",
          label: "content.products.productImages",
        },
        {
          type: "link",
          name: "spuName",
          label: "content.products.name",
          detail: {
            link: "https://sokogate.com/product-detail/",
            key: "id",
          },
        },
        {
          name: "maxPrice",
          label: "content.order.maxprice",
        },
        {
          name: "minPrice",
          label: "content.order.minprice",
        },
      ],
      dialogTableFun: GetProductList,
      dialogTableFunReq: {},
      recomm: {},
      productIdsTemp: [],
    };
  },
  mounted() {
    console.log("this:", this);
    this.initRecomm();
  },
  methods: {
    initRecomm() {
      const { type, name } = this;
      GetRecommList({
        type,
      }).then((res) => {
        console.log("GetRecommList-res:", res);
        if (res.data.rows && res.data.rows.length) {
          const exist = res.data.rows.some((row) => row.type === type);
          if (!exist) {
            return this.addRecomm(type, name);
          } else {
            this.recomm = res.data.rows.find((row) => row.type === type);
          }
        } else {
          return this.addRecomm(type, name);
        }
      });
    },
    addRecomm(type, name) {
      return AddRecomm({
        type,
        title: name,
        productIds: "",
      }).then((res) => {
        this.recomm = res.data;
      });
    },
    editRecomm(arr) {
      console.log("arr:", arr);
      const productIds = [...new Set(arr)];
      console.log("productIds:", productIds.filter(Boolean));
      this.loading = true;
      EditRecomm({
        id: this.recomm.ID,
        productIds: productIds.toString(),
      })
        .then((res) => {
          this.recomm = res.data;
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
          this.loading = false;
          this.editing = false;
          // this.$refs["list"].setList(res.data.list);
          this.initRecomm();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    add() {
      this.editing = true;
      const formData = {
        // type: this.type,
        ...defaultFormData,
      };
      this.formData = formData;
      this.$nextTick(() => {
        this.formData = formData;
      });
    },
    search(data) {
      console.log("search:", data);
      const { categoryIds, storeId, productName } = data;
      this.$refs["dialogList"].getList(null, null, {
        categoryIds,
        storeId,
        productName,
      });
    },
    submit() {
      const { productIdsTemp, recomm } = this;
      console.log("this", this);
      const oldIds = (recomm.spuList || []).map((v) => v.id);
      const newIds = productIdsTemp.map((v) => v.productId);
      this.editRecomm([...newIds, ...oldIds]);
    },
    close() {
      this.productIdsTemp = [];
      this.$refs["dialogList"].clearSelection();
      this.editing = false;
      this.formData = {};
      this.$refs["form"].resetFields();
    },
    del(e) {
      const {
        recomm: { productIds },
      } = this;
      const productList = productIds.split(",");
      try {
        const { id } = e;
        const index = productList.indexOf(id);
        productList.splice(index, 1);
        this.editRecomm(productList);
      } catch (error) {
        console.log(error);
      }
    },
    up(e) {
      // console.log("this:", this, "e:", e);
      const {
        recomm: { productIds },
      } = this;
      // console.log("productIds:", productIds);
      const productList = productIds.split(",");
      // console.log("productList:", productList);
      try {
        const { id } = e;
        const index = productList.indexOf(id);
        console.log("index", index);
        [productList[index], productList[index - 1]] = [
          productList[index - 1],
          productList[index],
        ];
        // console.log("productList:", productList);
        this.editRecomm(productList);
      } catch (error) {
        console.log(error);
      }
    },
    down(e) {
      const {
        recomm: { productIds },
      } = this;
      const productList = productIds.split(",");
      console.log("productList:", productList);
      try {
        const { id } = e;
        const index = productList.indexOf(id);
        console.log("index", index);
        [productList[index], productList[index + 1]] = [
          productList[index + 1],
          productList[index],
        ];
        this.editRecomm(productList);
      } catch (error) {
        console.log(error);
      }
    },
    backflow(count) {
      this.count = count;
    },
    select(ids) {
      // console.log(ids);
      this.productIdsTemp = ids;
    },
  },
};
</script>

<style lang="scss">
.page-card {
  width: 100%;
}
.plist {
  padding: 0 40px;
}
.dialog-footer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
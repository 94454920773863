<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import {required} from 'vuelidate/lib/validators'
import {getLists, deleteData, createData} from "../../../helpers/api/api";

export default {
    page: {
        title: 'Gallery',
    },
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            title: this.$t('content.gallery.text'),
            items: [
                {
                    text: this.$t('content.gallery.text'),
                },
                {
                    text: this.$t('content.gallery.lists'),
                    active: true
                }
            ],
            data: [],
            startIndex: 0,
            endIndex: 0,
            rows: 0,
            currentPage: 1,
            perPage: 9,
            editModal: false,
            url: '',
            name: '',
            image: '',
            tags: []
        }
    },
    validations: {
        name: {required},
        image: {required}
    },
    created() {
        this.lists(this.currentPage-1)
    },
    methods: {
        lists(page) {
            getLists('GET', 'image', page, 'desc').then(res => {
                this.data = res.data.rows;
                this.rows = res.data.count;
            })
        },

        submitData() {
            this.$v.$touch()
            if (this.$v.$invalid) {

                this.$swal({
                    text: this.$t('content.subscriptions.submitError'),
                    confirmButtonText: this.$t('common.ok')
                });

            } else {

                this.image = this.image.replace(/^data:image\/\w+;base64,/, "");

                createData('POST', 'image', {
                    name: this.name,
                    tags: this.tags,
                    image: this.image
                }).then(res => {
                    this.$swal({
                        text: res.message
                    });

                    this.lists(this.currentPage - 1);
                })
            }
        },

        createGallery() {
            this.editModal = true;
            this.name = '';
            this.image = '';
        },

        fileChange() {
            var that = this;
            var files = document.getElementById("image").files[0];
            var reader = new FileReader();
            reader.onloadend = function () {
                that.image = reader.result;
            };
            reader.readAsDataURL(files);
        },

        deleteGallery(id) {
            this.$swal({
                text: this.$t('common.confirmdelete'),
                confirmButtonText: this.$t('common.ok'),
                cancelButtonText: this.$t('common.cancel'),
                showCancelButton: true
            }).then((res) => {
                if (res.isConfirmed == true) {

                    deleteData('DELETE', 'image', id).then(res => {
                        this.$swal({
                            text: res.message,
                            confirmButtonText: this.$t('common.ok')
                        });

                        this.lists(this.currentPage - 1);
                    });

                }
            }); 
        },

        onPageChange() {
            this.lists(this.currentPage - 1);
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div>

                        <div class="row">
                            <div class="col-md-6">
                            <div class="product-title">
                                <div class="operate">
                                    <button class="btn btn-primary" @click="createGallery"><i class="ri-add-line"></i> {{$t('content.gallery.add')}}</button>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-6">
                            </div>
                        </div>

                        <div class="row no-gutters">


                            <div class="col-xl-4 col-sm-6" v-for="(item, index) in data" :key="index">
                                <div class="product-box">
                                    <div class="product-img">
                                    
                                    <div class="product-view">
                                        <a @click="deleteGallery(item._id)">
                                        <i class="ri-delete-bin-line"></i>
                                        </a>
                                    </div>
                                    <img
                                        :src="item.url"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                    />
                                    </div>

                                    <div class="text-center">
                                    <h5 class="font-size-15">
                                        <a href="#" class="text-dark">{{item.name}}</a>
                                    </h5>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <div class="col-xl-7">{{$t("common.showing")}} {{startIndex}} - {{endIndex}} of {{rows}}</div>
                                       
                            </div>
                            <div class="col-sm-6">
                                <div class="float-sm-right">
                                    <div class="row justify-content-md-between align-items-md-center">
                                         <!-- end col-->
                                        <div class="col-xl-5">
                                        <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                                            <b-pagination
                                            v-model="currentPage"
                                            :total-rows="rows"
                                            :per-page="perPage"
                                            @input="onPageChange"
                                            ></b-pagination>

                                        </div>
                                        </div>
                                        <!-- end col-->
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>



        <b-modal v-model="editModal" :title="$t('content.gallery.add')" centered>
        <form @submit.prevent="submit">
            <div class="form-group row-box">
                <input type="text" class="form-control" v-model.trim="$v.name.$model" :placeholder="$t('common.name')" />
                <span class="error" v-if="!$v.name.required">* {{$t('common.namerequired')}}</span>
            </div>

            <div class="form-group row-box">
                <input type="file" id="image" accept="image/*" class="form-control" :placeholder="$t('content.gallery.url')" @change="fileChange" />
                <span class="error" v-if="!$v.image.required">* {{$t('common.imagerequired')}}</span>
            </div>
        
        </form>
        <template v-slot:modal-footer>
            <b-button variant="secondary" @click="editModal = false">{{$t('common.close')}}</b-button>
            <b-button variant="primary" @click="submitData">
            {{$t('common.submit')}}
            <i class="fab fa-telegram-plane ml-1"></i>
            </b-button>
        </template>
        </b-modal>


    </Layout>
</template>
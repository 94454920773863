<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <el-row>
      <el-col>
        <el-button type="primary" @click="newPage" style="float: right">{{
          $t("common.viewstore")
        }}</el-button>
      </el-col>
    </el-row>
    <info-list
      title="content.vendors.storeinfo"
      v-model="user"
      :names="names"
    ></info-list>
    <h4 class="detail-h4">{{ $t("content.users.lists") }}</h4>
    <user-listby-storeid />
    <h4 class="detail-h4">{{ $t("content.vendors.productinfo") }}</h4>
    <store-product-list />
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import InfoList from "@/components/InfoList";
import StoreProductList from "@/components/StoreProductList";
import UserListbyStoreid from "@/components/UserListbyStoreid";
import { formatDataTime } from "@/utils";
import { GetStore } from "@/utils/api";
export default {
  components: {
    Layout,
    PageHeader,
    InfoList,
    StoreProductList,
    UserListbyStoreid,
  },
  data() {
    return {
      user: {},
      title: this.$t("content.vendors.StoreDetail"),
      items: [
        {
          text: this.$t("content.vendors.store"),
        },
        {
          text: this.$t("content.vendors.StoreDetail"),
          active: true,
        },
      ],
    };
  },
  computed: {
    names: function () {
      return this.$t("common");
    },
  },
  created() {
    this.getUserDetail();
  },
  methods: {
    newPage() {
      window.open(
        "https://www.sokogate.com/v2/store/collections?id=" +
          this.$route.query.id
      );
    },
    getUserDetail() {
      GetStore({ id: this.$route.query.id })
        .then((res) => {
          console.log("GetUserDetail-res", res);
          const { ...orderData } = res.data;
          const {
            // 商家信息
            id,
            storeName,
            logo,
            email,
            mobile,
            address,
            createAt,
            updateAt,
          } = orderData;
          this.user = {
            id,
            storeName,
            logo,
            email,
            mobile,
            address,
            createAt: formatDataTime(createAt),
            updateAt: formatDataTime(updateAt),
          };
        })
        .catch((err) => {
          console.log("GetUserDetail-err", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-h4 {
  color: #333;
  background: transparent;
  border: 1px solid transparent;
  padding: 12px 10px;
  font-size: 18px;
  font-weight: normal;
}
</style>
import CryptoJS from "crypto-js";
const moment = require('moment')

export const getFileNameMd5 = (file) => {
  return new Promise(function (resolve, reject) {
    try {
      const types = file.type.split("/");
      const folder = types[0];
      const type = types[1];
      var fileReader = new FileReader();

      //异步执行函数
      fileReader.onload = function (e) {
        const md5 = CryptoJS.MD5(
          CryptoJS.enc.Latin1.parse(e.target.result)
        ).toString();
        md5
          ? resolve(`${folder}/${md5}.${type}`)
          : reject(new Error("md5 error"));
      };

      //获取文件二进制数据
      fileReader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
};

export const findCategoryChildren = (_id, list) => {
  return list
    .filter((v) => v.parent === _id)
    .map((v) => {
      const children = findCategoryChildren(v._id, list);
      const options = children.length ? { children: children } : {};
      return {
        value: v._id,
        label: v.categoryName,
        ...options,
      };
    });
};

export const findCategorySelectChildren = (_id, list) => {
  return list
    .filter((v) => v.parentId === _id)
    .map((v) => {
      const children = findCategoryChildren(v.id, list);
      const options = children.length ? { children: children } : {};
      return {
        value: v.id,
        label: v.categoryName,
        ...options,
      };
    });
};

export function isEmpty(v) {
  switch (typeof v) {
    case "undefined":
      return true;
    case "string":
      if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0)
        return true;
      break;
    case "boolean":
      if (!v) return true;
      break;
    case "number":
      if (isNaN(v)) return true;
      break;
    case "object":
      if (null === v || v.length === 0) return true;
      for (var i in v) {
        return false;
      }
      return true;
  }
  return false;
}

// 两位小数
export function formatToDecimal(num) {
  // 格式化为小数，单位分 -> 元
  const float = parseFloat(num);
  if (isNaN(float)) {
    return 0;
  }
  return Math.round(num) / 100;
}

// 四位小数
// export function formatToDecimal(num) {
//   // 格式化为小数，单位分 -> 元
//   const float = parseFloat(num);
//   if (isNaN(float)) {
//     return 0;
//   }
//   return Math.round(num) / 10000;
// }

export function formatDataTime(unix) {
  // 格式化时间,戳转日期时间
  return moment.unix(unix).format('YYYY-MM-DD HH:mm:ss')
}

export function navto(path, query = {}) {
  window.vm.$router.push({
    path,
    query
  });
}

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getData, setStatus, createData, updateData} from "../../../helpers/api/api";
import { required} from 'vuelidate/lib/validators';
import { GetMyCategoryList } from "@/utils/api";

export default {
  page: {
      title: 'Category'
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.category.allcategory'),
      items: [
        {
          text: this.$t('content.category.text')
        },
        {
          text: this.$t('content.category.lists'),
          active: true
        }
      ],
      categoryData: [],
      submitted: false,
      showmodal: false,
      editCategoryModel: false,
      parentCategoryData: [],
      categoryName: '',
      parent: '',
      selected: 'selected',
      formTitle: '',
      editType: 'add',
      category_id: ''
    };
  },
  validations: {
      categoryName: {required}
  },
  created() {
     this.categoryLists(); 
  },
  methods: {

    // categoryLists() {
    //     getLists('GET', 'category').then(res => {
    //         this.categoryData = [];
    //         res.data.forEach(item => {
    //             item.status = item.status == 'Active' ? true : false;
    //             this.categoryData.push(item);
    //         })
    //     });
    // },

    categoryLists(page) {
        GetMyCategoryList({page}).then(res => {
            console.log('0000', res.data);
            this.categoryData = [];
            res.data.rows.forEach(item => {
                item.status = item.status == 'Active' ? true : false;
                this.categoryData.push(item);
            })
        });
    },

    createCategory(parent) {
        this.editCategoryModel = true;
        this.formTitle = this.$t('content.category.addnewcategory');
        this.editType = 'add';

        this.categoryName = '';
        this.parent = parent;
        
        this._parentCategoryLists(this.categoryData, parent);

    },

    editCategory(id) {
        this.editCategoryModel = true;
        this.formTitle = this.$t('content.category.editcategory');
        this.editType = 'edit';
        this.category_id = id;

        getData('GET', 'category', id).then(res => {
            this.categoryName = res.data.categoryName
            this.parent = res.data.parent == null ? '' : res.data.parent;

            this._childCategory(this.categoryData, this.parent)
        });
    },
    _parentCategory(data) {
        this.parentCategoryData = [];
        for (var i=0; i<data.length; i++) {
            if (data[i]['parent'] == null) {
                this.parentCategoryData.push(data[i]);
            }
        }
    },
    _childCategory(data, parent) {

        this._parentCategoryLists(data, parent); 
    },
    _parentCategoryLists(data, parent) {
        
        this.parentCategoryData = [];

        if (parent == '') {
            this._parentCategory(this.categoryData)
        } else {
            getData('GET', 'category', parent).then(res => {
                if (res.data.parent == null) {
                    this._parentCategory(this.categoryData)
                } else {
                    for (var i=0; i<data.length; i++) {
                        if (data[i]['parent'] == res.data.parent)
                            this.parentCategoryData.push(data[i]);
                    }
                }
            }); 
        } 
    },

    deleteCategory(id) {
        
        this.$swal({
            text: this.$t('common.confirmdelete'),
            confirmButtonText: this.$t('common.ok'),
            cancelButtonText: this.$t('common.cancel'),
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed == true) {

                setStatus('PUT', 'category', id, 2).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.categoryLists();
                });

            }
        }); 
        
    },

    setCategoryStatus(id, status) {
        var set_status = status == true ? 0 : 1;
        setStatus('PUT', 'category', id, set_status).then();
    },


    submitData() {
        
        this.editCategoryModel = false;

        this.$v.$touch()
        if (this.$v.$invalid) {
              
            this.$swal({
                text: this.$t('common.submitError'),
                confirmButtonText: this.$t('common.ok')
            });

        } else {

            if (this.editType == 'add') {

                createData('POST', 'category', {
                    categoryName: this.categoryName,
                    parent: this.parent
                }).then(res => {

                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.categoryLists();
                });
            } else {

                updateData('PUT', 'category', this.category_id, {
                    categoryName: this.categoryName,
                    parent: this.parent
                }).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                      this.categoryLists();
                });

            }
            
        } 

    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">

                <div class="row">
                    <div class="col-md-6">
                    <div class="product-title float-left">
                        <button class="btn-add" @click="createCategory('')"><i class="ri-add-line"></i>{{  $t('content.category.addnewcategory') }}</button>
                    </div>
                    </div>

                    <div class="col-md-6">
                    <div class="form-inline float-md-right">
                        <div class="search-box ml-2">
                        <div class="position-relative">
                            <input type="text" class="form-control rounded" :placeholder="$t('common.search')" />
                            <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>{{$t('common.id')}}</th>
                    <th>{{$t('common.name')}}</th>
                    <th>{{$t('content.category.parent')}}</th>
                    <th>{{$t('common.status')}}</th>
                    <th>{{$t('common.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in categoryData" :key="index">
                    <td>{{item.id}}</td>
                    <td>{{item.categoryName}}</td>
                    <td>{{item.parent}}</td>
                    <td>

                      <b-form-checkbox v-model="item.status" @change="setCategoryStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>

                    </td>
                    <td>
                    
                        <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.add')"
                        @click="createCategory(item._id)"
                      >
                        <i class="ri-add-fill font-size-18"></i>
                      </a>

                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editCategory(item._id)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteCategory(item._id)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal v-model="editCategoryModel" :title="formTitle" centered>
      <form>
          <div class="form-group">
            <input type="text" class="form-control" :placeholder="$t('common.name')" v-model.trim="$v.categoryName.$model" />
            <span class="error" v-if="!$v.categoryName.required">* {{$t('common.namerequired')}}</span>
           </div>

           <div class="form-group">
                <select class="form-control" v-model="parent">
                    <option value="">{{ $t('content.category.select') }}</option>
                    <option :value="item._id" v-for="(item, index) in parentCategoryData" :key="index" :v-if="parent == item._id">{{ item.categoryName }}</option>
                </select>
            </div>

            <input type="hidden" v-model="editType" />
            <input type="hidden" v-model="category_id" />
      </form>
      
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editCategoryModel = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData">
          {{$t('common.submit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

  </Layout>
</template>
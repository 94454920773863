<template>
  <div class="login-box" :gutter="20">
    <el-row type="flex" align="middle">
      <el-col :md="8" :lg="8">
        <el-row type="flex" align="middle" justify="center" class="my-login">
          <el-col>
            <img
              class="logo"
              src="@/assets/images/logo-dark.png"
              height="200"
              alt="logo"
            />
            <b-alert
              variant="danger"
              class="mt-3"
              v-if="notification.message"
              show
              dismissible
              >{{ notification.message }}</b-alert
            >
          </el-col>
          <el-col class="login-card">
            <sui-form
              ref="form"
              :list="formList"
              :defaultdata="formData"
              submitTitle="content.login.login"
              :submitWidth="100"
              @submit="submit"
              :loading="formLoading"
            />
            <div class="login-check-box">
              <div>
                <el-checkbox v-model="checked"></el-checkbox>
                <span class="agreement">{{
                  $t("content.login.rememberme")
                }}</span>
              </div>
            </div>
          </el-col>
          <el-col>
            <p class="login-txt">© 2022 Sokogate v0.0.2</p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="16" :lg="16">
        <div class="login-bg">
          <div class="bg-overlay"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { Login } from "@/utils/api";
import SuiForm from "@/components/s-ui/form";

export default {
  components: { SuiForm },
  data() {
    return {
      checked: false,
      formLoading: false,
      formList: [
        {
          type: "mail",
          name: "email",
          label: "common.email",
          rules: [
            {
              required: true,
              message: "common.emailrequired",
              trigger: "blur",
            },
            {
              type: "email",
              message: "common.emailFormatError",
              trigger: "blur",
            },
          ],
        },
        {
          type: "password",
          name: "password",
          label: "common.password",
          rules: [
            {
              required: true,
              message: "common.passwordrequired",
              trigger: "blur",
            },
            { eval: "validatePass", trigger: "blur" },
          ],
        },
      ],
      formData: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    // 登录
    submit(data) {
      console.log("submit-data", data);
      this.formLoading = true;
      Login(data)
        .then((res) => {
          console.log("login-res", res);
          this.formLoading = false;
          if (res.data.token) {
            console.log("token", res);
            localStorage.setItem("user", JSON.stringify(res.data.userInfo));
            localStorage.setItem("admin_user_token", res.data.token);

            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
          } else {
            this.$swal({
              text: this.$t("common.illegalAccountLogin"),
              confirmButtonText: this.$t("common.ok"),
            });
            return false;
          }
        })
        .catch((err) => {
          console.log("login-err", err);
          this.formLoading = false;
          this.$message({
            showClose: false,
            message: `${err.message}`,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/_responsive.scss";
.login-box {
  .my-login {
    padding: 1.5rem;
    .logo {
      display: block;
      margin: auto;
    }
    .login-card {
      padding-top: 3%;
      max-width: 75%;
      flex: 0 0 75%;
      .login-check-box {
        padding-left: 0;
      }
    }
    .login-txt {
      margin-top: 3%;
      text-align: center;
    }
  }
  .login-bg {
    background-image: url(/img/authentication-bg.d3694463.jpg);
    height: 100vh;
    background-size: cover;
    position: relative;
    background-position: center;
    .bg-overlay {
      background-color: #292626;
      position: absolute;
      height: 100%;
      width: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      opacity: 0.7;
    }
    @include mobile {
      display: none;
    }
  }
}
</style>

import {createData, getData} from "../helpers/api/api"
import md5 from 'js-md5';

class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader; 
    }
 
    // 启动上载过程
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var image = reader.result;
                    image = image.replace(/^data:image\/\w+;base64,/, "");

                    createData('POST', 'image', {
                        name: 'product-detail-'+ md5(new Date().toString()),
                        image: image
                    }).then(res => {
                        getData('GET', 'image', res.data._id).then(re => {
                            resolve({
                                default: re.data.url
                            })
                        });
                        
                    }).catch(error => {
                        reject(error)
                    });


                };
                reader.readAsDataURL(file);
                
                
                
            }));
    }
 
    // 中止上载过程
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }
}
 
function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        // 在这里将URL配置为后端上载脚本
        return new MyUploadAdapter(loader);
    };
}
 
export {
    MyUploadAdapter,
    MyCustomUploadAdapterPlugin
}
<template>
  <el-container>
    <!-- 左侧 -->
    <el-aside width="160px">
      <category-selector ref="selector" :type="211" @change="categoryChange" />
    </el-aside>
    <!-- 内容 -->
    <el-main v-if="showMaterialList.length" class="material-wrap">
      <el-row class="list-container">
        <el-col
          :span="6"
          v-for="(item, index) in showMaterialList"
          :key="item._id"
          class="cel-wrap"
        >
          <i v-if="type === 'video'" class="sokogate cel-play icon-play"></i>
          <label class="cel-label">
            <el-image
              v-if="type === 'image'"
              class="cel-image"
              :src="item.filename + '?x-oss-process=style/w256'"
              fit="contain"
            >
            </el-image>
            <el-image
              v-else-if="type === 'video'"
              class="cel-image"
              :src="
                item.Snapshot && item.Snapshot.CoverUrl
                  ? item.Snapshot.CoverUrl
                  : ''
              "
              fit="contain"
            >
            </el-image>
            <el-row>
              <el-col :span="4">
                <el-checkbox
                  class="cel-checkbox"
                  :checked="item.isCheck"
                  :disabled="
                    count > 0 &&
                    !item.isCheck &&
                    iCheckMaterialList.length === count
                  "
                  @change="setCheckList(iMaterialList[index], item.isCheck)"
                ></el-checkbox>
              </el-col>
              <el-col :span="20" class="cel-name">
                {{ item.filealia || item._id }}
              </el-col>
            </el-row>
          </label>
        </el-col>
      </el-row>
      <div class="pagination">
        <b>
          {{ $t("common.Selected") }} {{ iCheckMaterialList.length }}
          <span v-if="count > 0"> / {{ count }}</span>
        </b>
        <el-pagination
          background
          layout="total,prev, pager, next"
          :current-page="page"
          :page-size="pageSize"
          :total="total"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-main v-else>
      <el-empty :description="this.$t('common.emptyTips')"></el-empty>
    </el-main>
  </el-container>
</template>
<script>
import { renderData } from "@/helpers/api/api.js";
import CategorySelector from "@/components/CategorySelector";

export default {
  components: {
    CategorySelector,
  },
  props: {
    type: {
      require: true,
      type: String,
    },
    count: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      storeId: "",
      iMaterialList: [],
      iCheckMaterialList: [],
      total: 0,
      page: 1,
      categoryId: 0,
    };
  },
  computed: {
    showMaterialList: function () {
      return this.iMaterialList.map((v) => {
        return {
          isCheck: this.iCheckMaterialList.some(
            (selected) => v._id === selected._id
          ),
          ...v,
        };
      });
    },
  },
  created() {
    this.storeId = localStorage.getItem("storeId");
    this.getList();
  },
  methods: {
    categoryChange(id) {
      this.categoryId = Number(id);
      this.getList();
    },
    setCheckList(item, option) {
      // console.log("setCheckList:", item);
      if (option) {
        const curItem = this.iCheckMaterialList.find(
          (selected) => item._id === selected._id
        );
        const index = this.iCheckMaterialList.indexOf(curItem);
        // console.log("setCheckList-index:", index);
        this.iCheckMaterialList.splice(index, 1);
      } else {
        if (this.count === 0 || this.iCheckMaterialList.length < this.count) {
          this.iCheckMaterialList.push(item);
        } else {
          this.$message({
            message: this.$t("common.SelectMoreThanLimit"),
            type: this.$t("common.warning"),
          });
        }
      }
      console.log("iCheckMaterialList:", this.iCheckMaterialList);
      this.$emit("onCheck", this.iCheckMaterialList);
    },
    pageChange(page) {
      this.page = page;
      console.log("pageChange:", page);
      this.type === "image" ? this.getOssList(page) : this.getVodList(page);
    },
    getList(page) {
      this.page = page || this.page;
      this.type === "image" ? this.getOssList() : this.getVodList();
    },
    cleanList() {
      this.iCheckMaterialList = [];
      this.iMaterialList = [];
    },
    // 图片素材列表
    getOssList(page = 1) {
      console.log("page:", page);
      renderData(
        "GET",
        `oss/list?pageNumber=${page}&pageSize=${
          this.pageSize
        }&orderDir=desc&storeId=${this.storeId}&categoryId=${
          this.categoryId || ""
        }`
      ).then((res) => {
        console.log("getOssList-res:", res);
        // this.getHowManyPage(res.data.count);
        this.iMaterialList = res.data.rows;
        this.total = res.data.count;
      });
    },
    // 视频素材列表
    getVodList(page = 1) {
      console.log("page:", page);
      renderData(
        "GET",
        `vod/list?pageNumber=${page}&pageSize=${this.pageSize}&orderDir=desc&storeId=${this.storeId}`
      ).then((res) => {
        console.log("getVodList-res:", res);
        // this.getHowManyPage(res.data.count);
        this.iMaterialList = res.data.rows;
        this.total = res.data.count;
      });
    },
  },
};
</script>
<style lang="scss">
.cel {
  &-wrap {
    position: relative;
  }
  &-play {
    position: absolute;
    font-size: 100px;
    color: #fff;
    z-index: 3;
    top: 35px;
    left: 45px;
  }
  &-label {
    border: 1px solid #d1d1d1;
    display: inline-flex;
    height: 220px;
    margin-bottom: 12px;
    flex-direction: column;
  }
  &-image {
    width: 180px;
    height: 180px;
    background-image: url("http://oss.sokogate.com/static/photoshop_background.png");
  }

  &-checkbox {
    padding-top: 8px;
    padding-left: 10px;
  }

  &-name {
    padding-top: 8px;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    font-size: 14px;
  }
}
.list-container {
  min-height: 475px;
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-menu {
  height: 100%;
}
.material-wrap {
  background-color: #fff;
}
</style>
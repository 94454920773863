<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <store-list></store-list>
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import StoreList from "@/components/StoreList";
export default {
  components: { Layout, PageHeader, StoreList },
  data() {
    return {
      title: this.$t("content.vendors.store"),
      items: [
        {
          text: this.$t("content.vendors.store"),
        },
        {
          text: this.$t("content.vendors.storeList"),
          active: true,
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.vendor-list {
  font-size: 12px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists, updateData, createData, setStatus} from "../../../helpers/api/api";
import { required, minLength} from 'vuelidate/lib/validators'

export default {
    components: {
        Layout,
        PageHeader
    },
    page: {
        title: "Article",
        meta: [{ name: "description" }]
    },
    data() {
        return {
            title: this.$t('content.subscriptions.text'),
            items: [
                {
                    text: this.$t('content.subscriptions.text'),
                    href: "/"
                },
                {
                    text: this.$t('content.subscriptions.lists'),
                    active: true
                }
            ],
            fields: [
                { key: "_id", label: this.$t('common.id')},
                { key: "planName", sortable: true, label: this.$t('common.name') },
                { key: "price", sortable: true, label: this.$t('common.price')},
                { key: "statusText", sortable: true, label: this.$t('common.status') },
                { key: "action", label: this.$t('common.action') }
            ],
            filter: null,
            filterOn: [],
            createPlanModel: false,
            editPlanModel: false,
            planData: [],
            modalTitle: this.$t('content.subscriptions.edit'),
            editType: 'edit',
            plan_id: 0,
            price: 0,
            status: '',
            planName: "",
            shortDesc: "",
            platformFeatures: {
                postProducts: "",
                receiveAndRespondToInquiries: false,
                keywordAdSpend: "",
                showcasedProducts: "",
                keywordsTrends: false,
                trafficAccelerator: false,
                subAccounts: 0
            },
            onboarding: {
                productPostingService: 0,
                businessVerificationSupport: false,
                first90DaysFullAccountOperation: false
            },
            customerService: {
                customerService: "",
                performanceConsultation: ""
            }
        }
    },
    validations: {
      planName: { required, minLength: minLength(4)},
      price: {required},
      shortDesc: {required},
      platformFeatures: {
          postProducts: {required},
          subAccounts: {required},
          keywordAdSpend: {required},
          showcasedProducts: {required}
      },
      onboarding: {
          productPostingService: {required}
      }
    },
    created() {
        this.planLists();
    },
    methods: {

        planLists() {
            getLists('GET', 'vendorplan').then(res => {
                this.planData = []

                res.data.forEach(item => {
                    item.status = item.status == 0 ? true : false;
                    this.planData.push(item);
                });
            });
        },

        submitData() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                 
                this.$swal({
                    text: this.$t('content.subscriptions.submitError'),
                    confirmButtonText: this.$t('common.ok')
                });

            } else {

                if (this.editType == 'add') {
                    
                    createData('POST', 'vendorplan', {
                        planName: this.planName,
                        price: this.price,
                        shortDesc: this.shortDesc,
                        platformFeatures: this.platformFeatures,
                        onboarding: this.onboarding,
                        customerService: this.customerService
                    }).then(res => {
                        this.$swal({
                            text: res.message,
                            confirmButtonText: this.$t('common.ok')
                        });

                        this.planLists();
                    })

                } else {
                    updateData('PUT', 'vendorplan', this.plan_id, {
                        planName: this.planName,
                        price: this.price,
                        shortDesc: this.shortDesc,
                        platformFeatures: this.platformFeatures,
                        onboarding: this.onboarding,
                        customerService: this.customerService
                    }).then(res => {
                        this.$swal({
                            text: res.message,
                            confirmButtonText: this.$t('common.ok')
                        });

                        this.planLists();
                    })
                }
                
            }
        },

        createPlan() {
            this.editPlanModel = true;
            this.modalTitle = this.$t('content.subscriptions.add');
            this.editType = 'add';

            this.planName = '';
            this.price = '';
            this.shortDesc = '';
            this.platformFeatures = {};
            this.onboarding = {},
            this.customerService = {}
        },

        editPlan(id) {
            this.editPlanModel = true;
            this.modalTitle = this.$t('content.subscriptions.edit');
            this.editType = 'edit';

            for (var i=0; i<this.planData.length; i++) {
                if (this.planData[i]['_id'] == id) {
                    this.plan_id = this.planData[i]._id
                    this.planName = this.planData[i].planName;
                    this.status = this.planData[i].status;
                    this.price = this.planData[i].price;
                    this.shortDesc = this.planData[i].shortDesc;
                    this.onboarding = this.planData[i].onboarding;
                    this.platformFeatures = this.planData[i].platformFeatures;
                    this.customerService = this.planData[i].customerService;

                }
            }
        },

        deletePlan(id) {
            this.$swal({
                text: this.$t('common.confirmdelete'),
                confirmButtonText: this.$t('common.ok'),
                cancelButtonText: this.$t('common.cancel'),
                showCancelButton: true
            }).then((res) => {
                if (res.isConfirmed == true) {

                    setStatus('GET', 'vendorplan', id, 2).then(res => {
                        this.$swal({
                            text: res.message,
                            confirmButtonText: this.$t('common.ok')
                        });

                        this.planLists();
                    });

                }
            });
        },
        setSubStatus(id, status) {
            var set_status = status == true ? 0 : 1;
            setStatus('GET', 'vendorplan', id, set_status).then();
        },
    }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
                
                <div class="operate float-right">
                    <button class="btn btn-primary" @click="createPlan"><i class="ri-add-line"></i> {{$t('content.subscriptions.add')}}</button>
                </div>
                
                <div class="table-responsive">
                  <table
                    class="table table-centered datatable dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead class="thead-light">
                      <tr>
                        <th>{{$t('common.id')}}</th>
                        <th>{{$t('common.name')}}</th>
                        <th>{{$t('common.price')}}</th>
                        <th>{{$t('common.status')}}</th>
                        <th style="width: 80px;">{{$t('common.action')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in planData" :key="index">
                        <td>{{item._id}}</td>
                        <td>{{item.planName}}</td>
                        <td>{{item.price}}</td>
                        <td>

                        <b-form-checkbox v-model="item.status" @change="setSubStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>

                        </td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            class="mr-3 text-primary"
                            v-b-tooltip.hover
                            :title="$t('common.edit')"
                            @click="editPlan(item._id)"
                          >
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-danger"
                            v-b-tooltip.hover
                            :title="$t('common.delete')"
                            @click="deletePlan(item._id)"
                          >
                            <i class="mdi mdi-trash-can font-size-18"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="editPlanModel" :title="modalTitle" centered>
        <form @submit.prevent="submit">
          <div class="form-group row-box">
            <input type="text" class="form-control" :placeholder="$t('content.subscriptions.planname')" v-model.trim="$v.planName.$model" />
              <span class="error" v-if="!$v.planName.required">* {{$t('common.namerequired')}}</span>
          </div>

          <div class="form-group row-box">
            <div class="float-left"><input type="number" class="form-control" :placeholder="$t('content.subscriptions.price')" v-model.trim="$v.price.$model" />
            <span class="error" v-if="!$v.price.required">* {{$t('common.pricerequired')}}</span>
            </div>
            <div class="float-right"><input type="number" class="form-control" :placeholder="$t('content.subscriptions.productPostingService')" v-model.trim="$v.onboarding.productPostingService.$model" />
             <span class="error" v-if="!$v.onboarding.productPostingService.required">* {{$t('content.subscriptions.productPostingServiceRequired')}}</span>
            </div>
          </div>

          <div class="form-group row-box">
            <div class="float-left"><input type="number" class="form-control" :placeholder="$t('content.subscriptions.postProducts')" v-model.trim="$v.platformFeatures.postProducts.$model" />
            
            <span class="error" v-if="!$v.platformFeatures.postProducts.required">* {{$t('content.subscriptions.postProductsrequired')}}</span>
            </div>
            <div class="float-right"><input type="number" class="form-control" :placeholder="$t('content.subscriptions.subAccounts')" v-model.trim="$v.platformFeatures.subAccounts.$model" />
            
            <span class="error" v-if="!$v.platformFeatures.subAccounts.required">* {{$t('content.subscriptions.subAccountsrequired')}}</span>
            </div>
          </div>
          <div class="form-group row-box">
            <div class="float-left">
              
              <select class="form-control" v-model.trim="$v.platformFeatures.keywordAdSpend.$model">
                  <option>{{$t('content.subscriptions.keywordAdSpend')}}</option>
                  <option>USD 20000 - 50000</option>
                  <option>USD 50000 - 80000</option>
                  <option>USD 80000 - 100000</option>
              </select>
              <span class="error" v-if="!$v.platformFeatures.keywordAdSpend.required">* {{$t('content.subscriptions.keywordAdSpendrequired')}}</span>
            </div>
            <div class="float-right">

              <select class="form-control" v-model.trim="$v.platformFeatures.showcasedProducts.$model">
                <option>{{$t('content.subscriptions.showcasedProducts')}}</option>
                <option>10 Products</option>
                <option>50 Products</option>
                <option>100 Products</option>
            </select>

            <span class="error" v-if="!$v.platformFeatures.showcasedProducts.required">* {{$t('content.subscriptions.showcasedProductsrequired')}}</span>

            </div>
          </div>

          <div class="form-group row-box">
              <textarea class="form-control" :placeholder="$t('content.subscriptions.shortDesc')" v-model="$v.shortDesc.$model"></textarea>

              <span class="error" v-if="!$v.shortDesc.required">* {{$t('content.subscriptions.shortDescRequired')}}</span>
          </div>
          <div class="form-group">
              <input type="checkbox" checked v-model="platformFeatures.receiveAndRespondToInquiries"> {{$t('content.subscriptions.receiveAndRespondToInquiries')}}
          </div>
          <div class="form-group">
              <input type="checkbox" checked v-model="platformFeatures.keywordsTrends"> {{$t('content.subscriptions.keywordsTrends')}}
          </div>
          <div class="form-group">
              <input type="checkbox" checked v-model="platformFeatures.trafficAccelerator"> {{$t('content.subscriptions.trafficAccelerator')}}
          </div>
          <div class="form-group">
              <input type="checkbox" checked v-model="onboarding.businessVerificationSupport"> {{$t('content.subscriptions.businessVerificationSupport')}}
          </div>
          <div class="form-group">
              <input type="checkbox" checked v-model="onboarding.first90DaysFullAccountOperation"> {{$t('content.subscriptions.first90DaysFullAccountOperation')}}
          </div>

          <div class="form-group">
              {{$t('content.subscriptions.customerService')}}: <input type="radio" value="Yes" checked v-model="customerService.customerService" />{{$t('common.yes')}} &nbsp;&nbsp;<input type="radio" v-model="customerService.customerService" value="No" />{{$t('common.no')}}
          </div>

          <div class="form-group">
              {{$t('content.subscriptions.performanceConsultation')}}: <input type="radio" value="Yes" v-model="customerService.performanceConsultation" />{{$t('common.yes')}} &nbsp;&nbsp;<input type="radio" value="No" v-model="customerService.performanceConsultation" />{{$t('common.no')}}
          </div>

          <input type="hidden" v-model="plan_id" />

        </form>
        <template v-slot:modal-footer>
          <b-button variant="secondary" @click="editPlanModel = false">{{$t('common.close')}}</b-button>
          <b-button variant="primary" @click="submitData">
            {{$t('common.submit')}}
            <i class="fab fa-telegram-plane ml-1"></i>
          </b-button>
        </template>
    </b-modal>

  </Layout>
</template>
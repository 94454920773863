<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists, getData, setStatus, editStatus, updateData} from "../../../helpers/api/api";
import { required } from "vuelidate/lib/validators";
const moment = require('moment')

export default {
  page: {
      title: "Store",
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.vendors.store'),
      items: [
        {
          text: this.$t('content.vendors.store')
        },
        {
          text: this.$t('content.vendors.storeList'),
          active: true
        }
      ],
      storeData: [],
      submitted: false,
      editStoreModal: false,
      startIndex: 0,
      endIndex: 0,
      totalRows: 100,
      currentPage: 1,
      perPage: 10,
      statusData: [
          {
              value: 0,
              label: "review"
          },
          {
              value: 1,
              label: "normal"
          },
          {
              value: 2,
              label: "arrearage"
          },
          {
              value: 3,
              label: "close"
          },
          {
              value: 5,
              label: "refuse"
          }
      ],
      store: {
          name: '',
          email: '',
          logo: '',
          mainCategoryId: '',
          mobile: '',
          profile: '',
          status: '',
          address: ''
      },
      categories:[],
      company: {
          businessLicenseImg: '',
          companyProfile: '',
          identityCardBack: '',
          identityCardFront: '',
          nameOfLegalPerson: '',
          nationalEmblemImg: '',
          productCert: '',
          senterpriseCertImg: '',
          trademartCert: '',
          identityNumber: '',
          enterpriseName: ''
      },
      id: '',
      statusModalTitle: '',
      editStatusModal: false,
      refuseContent: '',
      refuseStatus: '',
      banks: {
        bank: {
            account: '',
            accountNo: '',
            bankName: ''
        },
        overseas: {
            accountNo: '',
            bankAddress:'',
            bankName: '',
            beneficiary: '',
            swift: ''
        }
      }
    };
  },
  validations: {
      store: {
          name: {required},
          email: {required},
          //logo: {required},
          mainCategoryId: {required},
          mobile: {required},
          address: {required}
      },
      company: {
         // businessLicenseImg: {required},
          companyProfile: {required},
          //identityCardBack: {required},
          //identityCardFront: {required},
          nameOfLegalPerson: {required},
          //nationalEmblemImg: {required},
         // productCert: {required},
         // senterpriseCertImg: {required},
         // trademartCert: {required},
          identityNumber: {required},
          enterpriseName: {required}
      },
      banks: {
          bank: {
              account: {required},
              accountNo: {required},
              bankName: {required}
          },
          overseas: {
              bankAddress: {required},
              bankName: {required},
              beneficiary: {required},
              swift: {required},
              accountNo: {required}
          }
      }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.storeData.length;
    }
  },
  created() {
      this.storeLists(this.currentPage);
  },
  methods: {
      storeLists(page) {
          getLists('GET', 'store', page, 'desc').then(res => {
              this.storeData = [];
              res.data.rows.forEach(item => {
                  item.createdAt = moment(item.created_at).format('MMMM,DD YYYY HH:mm:ss');

                  this.statusData.forEach(status => {
                      if (status.value == item.status) {
                          item.status = this.$t('content.vendors.'+ status.label)
                      }
                  });
                  this.storeData.push(item);
              })
              this.totalRows = res.data.count;
          });
      },
      getCategory() {
          getLists('GET', 'category').then(res => {
            this.categories = [];
            res.data.forEach(item => {
                if (item.parent == null) {
                    this.categories.push(item);
                }
            })
        });
      },
      editStore(id) {
          this.editStoreModal = true;
          this.getCategory();
          this.id = id;

          getData('GET', 'store', id).then(res => {
              this.store = {
                  name: res.data.name,
                  email: res.data.email,
                  logo: res.data.logo,
                  mainCategoryId: res.data.mainCategoryId,
                  mobile: res.data.mobile,
                  profile: res.data.profile,
                  address: res.data.address,
                  status: res.data.status
              };
              this.company = {
                  businessLicenseImg: res.data.storeCertId.businessLicenseImg,
                  companyProfile: res.data.storeCertId.companyProfile,
                  enterpriseName: res.data.storeCertId.enterpriseName,
                  identityCardBack: res.data.storeCertId.identityCardBack,
                  identityCardFront: res.data.storeCertId.identityCardFront,
                  identityNumber: res.data.storeCertId.identityNumber,
                  nameOfLegalPerson: res.data.storeCertId.identityNumber,
                  productCert: res.data.storeCertId.productCert,
                  trademarkCert: res.data.storeCertId.trademarkCert
              };
              this.banks = {
                bank: res.data.bank,
                overseas: res.data.overseas
              }
              console.log(res.data)
          });
          
      },

      editStatus() {
          editStatus('PUT', 'store', this.id, this.refuseStatus, {
              failResult: this.refuseContent
          }).then(res => {
              this.$swal({
                  text: res.message,
                  confirmButtonText: this.$t('common.ok')
              });
          });
          this.storeLists(this.currentPage);
          this.editStatusModal = false;
      },
      saveStatus(status) {

          let text = this.$t('content.vendors.confirmAudit');
          if (status == 5) {
              this.editStatusModal = true;
              this.statusModalTitle = this.$t('content.vendors.statusRefuseText');
              this.refuseStatus = 5;
              return false;
          } else if (status == 3) {
              this.editStatusModal = true;
              this.statusModalTitle = this.$t('content.vendors.statusCloseStoreText');
              this.refuseStatus = 3;
              return false;
          }

          this.$swal({
              text: text,
              confirmButtonText: this.$t('common.ok'),
              cancelButtonText: this.$t('common.cancel'),
              showCancelButton: true
          }).then((res) => {
              if (res.isConfirmed == true) {

                  setStatus('PUT', 'store', this.id, status).then(res => {
                        this.$swal({
                            text: res.message,
                            confirmButtonText: this.$t('common.ok')
                        });
                  });

                  this.storeLists(this.currentPage);
              } 

              this.editStoreModal = false;
          });
      },

      submitData() {
          this.$v.$touch()
          if (this.$v.$invalid) {
                
              this.$swal({
                  text: this.$t('common.submitError'),
                  confirmButtonText: this.$t('common.ok')
              });

          } else {

            console.log({
                    store: this.store,
                    banks: this.banks,
                    storeCert: this.company
                })
           
               updateData('PUT', 'store', this.id, {
                    store: this.store,
                    banks: this.banks,
                    storeCert: this.company
                }).then(res => {
                    this.$swal({
                        text: res.message
                    });
                    this.storeLists(this.currentPage)
                })
          }

        },

        deleteStore(id) {
          
          this.$swal({
              text: this.$t('common.confirmdelete'),
              confirmButtonText: this.$t('common.ok'),
              cancelButtonText: this.$t('common.cancel'),
              showCancelButton: true
          }).then((res) => {
              if (res.isConfirmed == true) {

                  setStatus('PUT', 'store', id, 4).then(res => {
                      this.$swal({
                          text: res.message,
                          confirmButtonText: this.$t('common.ok')
                      });

                      this.storeLists(this.currentPage);
                  });

              }
          }); 
          
      },

      
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      onPageChange() {
          this.startIndex = (this.currentPage - 1) * this.perPage;
          this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
          this.storeLists(this.currentPage);
      }
  }
};
</script>

<style scoped>
.row-box .col-lg-2 {
  border: 1px #f8f8f8 solid;
  border-radius: 10px;
  margin-left: 10px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <!-- <th>{{$t('common.id')}}</th> -->
                    <th>{{$t('common.name')}}</th>
                    <th>{{$t('content.vendors.vendorName')}}</th>
                    <th>{{$t('common.email')}}</th>
                    <th>{{$t('common.mobile')}}</th>
                    <th>{{$t('common.category')}}</th>
                    <th>{{$t('common.status')}}</th>
                    <th>{{$t('content.orders.onSaleProductNumber')}}</th>
                    <th>{{$t('content.orders.offSaleProductNumber')}}</th>
                    <th>{{$t('common.createtime')}}</th>
                    <th style="width: 80px;">{{$t('common.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in storeData" :key="index">
                    <!-- <td>{{item._id}}</td> -->
                    <td><a :href="`https://sokogate.com/store/collections/${item._id}`" target="_blank">{{ item.name }}</a></td>
                    <!-- <td>{{item.name}}</td> -->
                    <td>{{item.vendorName}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.mobile}}</td>
                    <td>{{item.mainCategory.categoryName}}</td>
                    <td>{{item.status}}</td>
                    <td>{{item.normalProductCount}}</td>
                    <td>{{item.hiddenProductCount}}</td>
                    <td>{{item.createdAt}}</td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editStore(item._id)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteStore(item._id)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row justify-content-md-between align-items-md-center">
                
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="onPageChange"></b-pagination>
                      </ul>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="editStatusModal" :title="statusModalTitle" centered>
        
        <form>
            <div class="form-group">
                <label for="profile">{{$t('content.vendors.reason')}}</label>
                <textarea v-model="refuseContent" cols="5" class="form-control" ></textarea>
            </div>
        </form>
        
        <template v-slot:modal-footer>
          <b-button variant="secondary" @click="editStatusModal = false">{{$t('common.close')}}</b-button>
          <b-button variant="primary" @click="editStatus">
            {{$t('common.save')}}
          </b-button>
        </template>
      </b-modal>
    
    <b-modal v-model="editStoreModal" :title="$t('common.view')" centered size="xl">
      
      <form>
          
          <div class="row row-box">
              <div class="col-lg-6">

                <div class="form-group">
                    <label for="name">{{$t('content.vendors.name')}}</label>
                    <input id="name" v-model="$v.store.name.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.store.name.required">* {{$t('content.vendors.nameRequired')}}</span>
                </div>

              </div>
              <div class="col-lg-6">

                  <div class="form-group">
                      <label for="mainCategoryId">{{$t('content.vendors.mainCategory')}}</label>
                      <select class="form-control" v-model="$v.store.mainCategoryId.$model">
                          <option v-for="(item, index) in categories" :key="index" :value="item._id">{{item.categoryName}}</option>
                      </select>
                      <span class="error" v-if="!$v.store.mainCategoryId.required">* {{$t('content.vendors.mainCategoryRequired')}}</span>
                  </div>

              </div>
          </div>

          <div class="form-group">
              <label for="profile">{{$t('content.vendors.profile')}}</label>
              <textarea v-model="store.profile" cols="5" class="form-control" ></textarea>
          </div>



          <div class="row row-box">
              <div class="col-lg-4">

                 <div class="form-group">
                    <label for="enterpriseName">{{$t('content.vendors.companyName')}}</label>
                    <input id="enterpriseName" v-model="$v.company.enterpriseName.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.company.enterpriseName.required">* {{$t('content.vendors.enterpriseNameRequired')}}</span>
                </div>

              </div>
              <div class="col-lg-4">

                 <div class="form-group">
                    <label for="nameOfLegalPerson">{{$t('content.vendors.nameOfLegalPerson')}}</label>
                    <input id="nameOfLegalPerson" v-model="$v.company.nameOfLegalPerson.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.company.nameOfLegalPerson.required">* {{$t('content.vendors.nameOfLegalPersonRequired')}}</span>
                </div>

              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="identityNumber">{{$t('content.vendors.identityNumber')}}</label>
                        <input id="identityNumber" v-model="$v.company.identityNumber.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.company.identityNumber.required">* {{$t('content.vendors.identityNumberRequired')}}</span>
                    </div>
              </div>
          </div>

          <div class="row row-box">
              <div class="col-lg-4">

                 <div class="form-group">
                    <label for="email">{{$t('content.vendors.email')}}</label>
                    <input id="email" v-model="$v.store.email.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.store.email.required">* {{$t('content.vendors.emailRequired')}}</span>
                </div>

              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="mobile">{{$t('content.vendors.mobile')}}</label>
                        <input id="mobile" v-model="$v.store.mobile.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.store.mobile.required">* {{$t('content.vendors.mobileRequired')}}</span>
                    </div>
              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="address">{{$t('content.vendors.companyAddress')}}</label>
                        <input id="address" v-model="$v.store.address.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.store.address.required">* {{$t('content.vendors.addressRequired')}}</span>
                    </div>
              </div>
          </div>
          <div class="form-group">
              <label for="companyProfile">{{$t('content.vendors.companyProfile')}}</label>
              <textarea v-model="$v.company.companyProfile.$model" cols="5" class="form-control" ></textarea>
              <span class="error" v-if="!$v.company.companyProfile.required">* {{$t('content.vendors.companyProfileRequired')}}</span>
          </div>

          <div class="row row-box">
            <div class="col-lg-2" v-if="store.logo">
                  <a :href="store.logo" target="_blank"><img :src="store.logo" width="150" /></a>
              </div>
              <div class="col-lg-2" v-if="company.businessLicenseImg">
                  <a :href="company.businessLicenseImg" target="_blank"><img :src="company.businessLicenseImg" width="150" /></a>
              </div>
              <div class="col-lg-2" v-if="company.identityCardFront">
                  <a :href="company.identityCardFront" target="_blank"><img :src="company.identityCardFront" width="150" /></a>
              </div>
              <div class="col-lg-2" v-if="company.identityCardBack">
                  <a :href="company.identityCardBack" target="_blank"><img :src="company.identityCardBack" width="150" /></a>
              </div>
              <div class="col-lg-2" v-if="company.nationalEmblemImg">
                  <a :href="company.nationalEmblemImg" target="_blank"><img :src="company.nationalEmblemImg" width="150" /></a>
              </div>
              <div class="col-lg-2" v-if="company.productCert">
                  <a :href="company.productCert" target="_blank"><img :src="company.productCert" width="150" /></a>
              </div>
              <div class="col-lg-2" v-if="company.trademarkCert">
                 <a :href="company.trademarkCert" target="_blank"><img :src="company.trademarkCert" width="150" /></a>
              </div>
          </div>

          <div class="row row-box">
              <div class="col-lg-4">
                 <div class="form-group">
                    <label for="bankaccount">{{$t('content.vendors.bankAccount')}}</label>
                    <input id="bankaccount" v-model="$v.banks.bank.account.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.banks.bank.account.required">* {{$t('content.vendors.bankAccountRequired')}}</span>
                </div>
              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="accountNo">{{$t('content.vendors.accountNo')}}</label>
                        <input id="accountNo" v-model="$v.banks.bank.accountNo.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.banks.bank.accountNo.required">* {{$t('content.vendors.accountNoRequired')}}</span>
                    </div>
              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="bankName">{{$t('content.vendors.bankName')}}</label>
                        <input id="bankName" v-model="$v.banks.bank.bankName.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.banks.bank.bankName.required">* {{$t('content.vendors.bankNameRequired')}}</span>
                    </div>
              </div>
          </div>
          <div class="row" style="border-bottom: 1px #f8f8f8 solid; margin-top: 15px; color: red; padding-left: 7px;">
              {{$t('content.vendors.overseas')}}
          </div>
          <div class="row row-box">
              <div class="col-lg-4">
                 <div class="form-group">
                    <label for="beneficiary">{{$t('content.vendors.beneficiary')}}</label>
                    <input id="beneficiary" v-model="$v.banks.overseas.beneficiary.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.banks.overseas.beneficiary.required">* {{$t('content.vendors.beneficiaryRequired')}}</span>
                </div>
              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="accountNo">{{$t('content.vendors.accountNo')}}</label>
                        <input id="accountNo" v-model="$v.banks.overseas.accountNo.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.banks.overseas.accountNo.required">* {{$t('content.vendors.accountNoRequired')}}</span>
                    </div>
              </div>
              <div class="col-lg-4">
                    <div class="form-group">
                        <label for="bankName">{{$t('content.vendors.bankName')}}</label>
                        <input id="bankName" v-model="$v.banks.overseas.bankName.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.banks.overseas.bankName.required">* {{$t('content.vendors.bankNameRequired')}}</span>
                    </div>
              </div>
          </div>
          <div class="row row-box">
              <div class="col-lg-4">
                 <div class="form-group">
                    <label for="swift">{{$t('content.vendors.swift')}}</label>
                    <input id="swift" v-model="$v.banks.overseas.swift.$model" type="text" class="form-control" />
                    <span class="error" v-if="!$v.banks.overseas.swift.required">* {{$t('content.vendors.swiftRequired')}}</span>
                </div>
              </div>
              <div class="col-lg-8">
                    <div class="form-group">
                        <label for="bankAddress">{{$t('content.vendors.bankAddress')}}</label>
                        <input id="bankAddress" v-model="$v.banks.overseas.bankAddress.$model" type="text" class="form-control" />
                        <span class="error" v-if="!$v.banks.overseas.bankAddress.required">* {{$t('content.vendors.bankAddressRequired')}}</span>
                    </div>
              </div>
          </div>

      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editStoreModal = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData" v-if="store.status==0">
          {{$t('common.save')}}
        </b-button>
        <b-button variant="primary" @click="saveStatus(2)" v-if="store.status==0">
          {{$t('content.vendors.audit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>

        <b-button variant="primary" style="background-color: red; border: none;" @click="saveStatus(5)" v-if="store.status==2 || store.status == 0">
          {{$t('content.vendors.refuse')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
         <b-button variant="primary" style="background-color: #089d57; border: none;" @click="saveStatus(1)" v-if="store.status==2">
          {{$t('content.vendors.paid')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>

        <b-button variant="primary" style="background-color: red; border: none;" @click="saveStatus(3)" v-if="store.status==1">
          {{$t('content.vendors.closeStore')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>

        <b-button variant="primary" style="background-color: red; border: none;" @click="saveStatus(1)" v-if="store.status==3">
          {{$t('content.vendors.reOpenStore')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>


      </template>
    </b-modal>

  </Layout>
</template>
<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      @backflow="backflow"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { GetRecordList } from "@/utils/api";
import { formatDataTime } from "@/utils";

export default {
  props: {
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiList },
  data() {
    return {
      tableColumn: [
        {
          name: "userId",
          label: "content.order.userId",
        },
        {
          name: "ip",
          label: "content.order.ip",
        },
        {
          name: "path",
          label: "content.order.path",
        },

        {
          name: "status",
          label: "content.order.status",
          //   status: this.$t("content.order.statusText"),
        },
        {
          name: "recordType",
          label: "content.order.recordType",
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      tableFun: GetRecordList,
      tableFunReq: {
        userId: this.$route.query.id,
        orderKey: "",
        page: 1,
        pageSize: this.max || 10,
        // type:0
      },
      count: 0,
    };
  },
  methods: {
    backflow(count) {
      this.count = count;
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>
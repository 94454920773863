<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import SuiForm from "@/components/s-ui/form";
import { AddStore } from "@/utils/api";
import { renderData } from "@/helpers/api/api.js";

export default {
  page: {
    title: "Add Store",
  },
  components: {
    Layout,
    PageHeader,
    SuiForm,
  },
  data() {
    return {
      title: this.$t("content.vendors.store"),
      items: [
        {
          text: this.$t("content.vendors.store"),
        },
        {
          text: this.$t("content.vendors.storeAdd"),
          active: true,
        },
      ],
      category: [],
      data: {
        name: "",
        phoneCode: "86",
        mobile: "",
        email: "",
        password: "",
        confirmpassword: "",
        country: "",
        kind: 0,
        storeName: "",
        categoryId: "",
        address: "",
        profile: "",
        enterpriseName: "",
        businessLicenseImg: "",
        identityCardFront: "",
        identityCardBack: "",
        brandLogo: "",
        productCert: "",
        trademarkCert: "",
      },
      list: [
        {
          type: "title",
          label: "content.vendors.accountinfo",
        },
        {
          type: "input",
          name: "name",
          label: "content.vendors.name",
          rules: [
            { required: true, message: "common.namerequired", trigger: "blur" },
          ],
        },
        {
          type: "mobile",
          name: "mobile",
          label: "content.vendors.mobile",
          rules: [
            {
              required: true,
              message: "common.mobilerequired",
              trigger: "blur",
            },
            {
              eval: "validateGlobalMobile",
              message: "common.mobileMatch",
              trigger: "blur",
            },
          ],
        },
        {
          type: "input",
          name: "email",
          label: "content.vendors.email",
          rules: [
            {
              required: true,
              message: "common.emailrequired",
              trigger: "blur",
            },
            {
              type: "email",
              message: "common.emailFormatError",
              trigger: "blur",
            },
          ],
        },
        {
          type: "password",
          name: "password",
          label: "common.password",
          rules: [
            {
              required: true,
              message: "common.passwordrequired",
              trigger: "blur",
            },
            { eval: "validatePass", trigger: "blur" },
          ],
        },
        {
          type: "password",
          name: "confirmpassword",
          label: "common.confirmpassword",
          rules: [
            {
              required: true,
              message: "common.passwordrequired",
              trigger: "blur",
            },
            {
              eval: "validatePass2",
              message: "common.passwordConfirmError",
              trigger: "blur",
            },
          ],
        },
        {
          type: "country",
          name: "country",
          label: "common.country",
          rules: [
            {
              required: true,
              message: "common.countryrequired",
              trigger: "blur",
            },
          ],
        },
        {
          type: "title",
          label: "content.vendors.storeinfo",
        },
        {
          type: "radio",
          name: "kind",
          label: "content.vendors.type",
          rules: [{ required: true }],
          range: [
            {
              label: "content.vendors.Seller",
              value: 0,
            },
            {
              label: "content.vendors.Logistics company",
              value: 1,
            },
          ],
          setHidden: ["categoryId", 0, 0],
        },
        {
          type: "input",
          name: "storeName",
          label: "content.vendors.storeName",
          rules: [
            {
              required: true,
              message: "content.vendors.nameRequired",
              trigger: "blur",
            },
          ],
        },
        {
          type: "categoryselect",
          name: "categoryId",
          label: "content.resident.main",
          range: [],
          rules: [
            {
              required: true,
              message: "content.resident.mainreg",
              trigger: "blur",
            },
          ],
        },
        {
          type: "title",
          label: "content.resident.enterprise",
        },
        {
          type: "input",
          name: "enterpriseName",
          label: "content.resident.enterpriseName",
          rules: [
            {
              required: true,
              message: "content.resident.enterpriseNamesreg",
              trigger: "blur",
            },
          ],
        },
        {
          type: "input",
          name: "address",
          label: "content.resident.detailed",
          rules: [
            {
              required: true,
              message: "content.resident.detailedreg",
              trigger: "blur",
            },
          ],
        },
        {
          type: "textarea",
          name: "profile",
          label: "content.resident.company",
          rules: [
            {
              required: true,
              message: "content.resident.companyreg",
              trigger: "blur",
            },
          ],
        },
        {
          type: "uploadimage",
          name: "businessLicenseImg",
          label: "content.resident.businessLicenseImg",
          rules: [
            {
              required: true,
              message: "common.imagerequired",
              trigger: "blur",
            },
          ],
        },
        {
          type: "uploadimage",
          name: "identityCardFront",
          label: "content.resident.identityCardFront",
          rules: [
            {
              required: true,
              message: "common.imagerequired",
              trigger: "blur",
            },
          ],
        },
        {
          type: "uploadimage",
          name: "identityCardBack",
          label: "content.resident.identityCardBack",
          rules: [
            {
              required: true,
              message: "common.imagerequired",
              trigger: "blur",
            },
          ],
        },
        {
          type: "uploadimage",
          name: "brandLogo",
          label: "content.resident.brandLogo",
        },
        {
          type: "uploadimage",
          name: "productCert",
          label: "content.resident.productCert",
        },
        {
          type: "uploadimage",
          name: "trademarkCert",
          label: "content.resident.trademarkCert",
        },
      ],
    };
  },
  validations: {},
  computed: {},
  created() {
    this.getCategory();
  },
  methods: {
    submit(data) {
      console.log("submit:", data);
      AddStore(data)
        .then((res) => {
          console.log("AddStore-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.addSuccess"),
          });
          setTimeout(() => {
            this.$router.replace("/vendors/index");
          }, 1000);
        })
        .catch((err) => {
          console.log("AddStore-err:", err);
          this.$message({
            type: "error",
            message: this.$t(`errmsg.${err.errmsg}`),
          });
        });
    },

    getCategory() {
      renderData("GET", "category?orderDir=desc")
        .then((res) => {
          console.log("getCategory-res:", res);
          this.$set(this.list, 10, {
            ...this.list[10],
            range: res.data
              .filter((v) => !v.parent)
              .map((v) => {
                return {
                  label: `category.${v.categoryName}`,
                  value: v._id,
                };
              }),
          });
          // this.list[3] = undefined;
          // this.list = this.list.map(v => {
          //   if (v.name === "categoryId") {
          //     return {
          //       ...v,
          //       range: res.data
          //         .filter(v => !v.parent)
          //         .map(v => {
          //           return {
          //             label: `category.${v.categoryName}`,
          //             value: v._id
          //           };
          //         })
          //     };
          //   } else {
          //     return v;
          //   }
          // });
          console.log("this.list:", this.list);
        })
        .catch((error) => {
          console.log("getCategory-error:", error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <el-descriptions
              :title="$t('content.vendors.storeAdd')"
            ></el-descriptions>
            <sui-form
              class="form"
              :list="list"
              :defaultdata="data"
              @submit="submit"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.form {
  max-width: 600px;
}
</style>
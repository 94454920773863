<template>
  <el-select
    :value="value"
    :placeholder="$t('common.selectPlaceholder')"
    autocomplete="autocomplete"
    filterable
    clearable
    @change="handleChange"
  >
    <el-option
      v-for="(item, index) in list"
      :key="`store-${index}`"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import { GetVendorSearchList } from "@/utils/api";

export default {
  props: {
    value: {
      default: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },

  data() {
    return {
      list: [],
    };
  },

  created() {
    this.getList();
  },

  methods: {
    getList() {
      GetVendorSearchList({
        search: "",
        orderKey: "",
        pageSize: 999,
      }).then((res) => {
        // console.log("getStoreList-res:", res);
        this.list = res.data.rows.map((v) => {
          return {
            label: v.userName,
            value: v.storeId,
          };
        });
      });
    },

    handleChange(e) {
      console.log("handleChange:", e);
      this.$emit("input", e);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <el-tabs :value="tabs[0].name">
      <template v-for="(tab, index) in tabs">
        <el-tab-pane
          :key="`tab-${index}`"
          :label="$t(tab.name)"
          :name="tab.name"
        >
          <component
            v-bind:is="tab.component"
            :type="tab.type"
            :name="tab.name"
            :max="tab.max"
          ></component>
        </el-tab-pane>
      </template>
    </el-tabs>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import BannerList from "@/components/BannerList";
import ProductList from "@/components/ProductList";
import CategoryList from "@/components/CategoryList";

export default {
  page: {
    title: "Homepage Setting",
  },

  components: {
    Layout,
    PageHeader,
    BannerList,
    ProductList,
    CategoryList,
  },

  data() {
    return {
      title: this.$t("content.homePage.text"),

      items: [
        {
          text: this.$t("content.homePage.text"),
        },
        {
          text: this.$t("content.homePage.lists"),
          active: true,
        },
      ],

      tabs: [
        {
          type: 601,
          name: "content.homePage.main_slider",
          max: 5,
          component: "banner-list",
        },
        {
          type: 602,
          name: "content.homePage.second_slider",
          max: 5,
          component: "banner-list",
        },
        {
          type: 111,
          name: "content.homePage.selected_products",
          max: 10,
          component: "product-list",
        },
        {
          type: 112,
          name: "content.homePage.popular_products",
          max: 10,
          component: "product-list",
        },
        {
          type: 113,
          name: "content.homePage.popular_products_bottom",
          max: 10,
          component: "product-list",
        },
        {
          type: 114,
          name: "content.homePage.best_sale_products",
          max: 10,
          component: "product-list",
        },
        {
          type: 115,
          name: "content.homePage.may_like_products",
          max: 10,
          component: "product-list",
        },
        {
          type: 115,
          name: "content.homePage.most_search_keywords",
          max: 10,
          component: "category-list",
        },
      ],
    };
  },
};
</script>

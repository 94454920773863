<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { getLists, setStatus } from "../../../helpers/api/api";
import { GetUserSearchList, GetCountryList, EditUser } from "@/utils/api";
const moment = require("moment");
import { required } from "vuelidate/lib/validators";

/**
 * Customers Component
 */
export default {
  page: {
    title: "Admin",
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: this.$t("content.admin.text"),
      items: [
        {
          text: this.$t("content.admin.text"),
        },
        {
          text: this.$t("content.admin.lists"),
          active: true,
        },
      ],
      vendorsData: [],
      submitted: false,
      isAdmin: false,
      showmodal: false,
      editVendorModel: false,
      startIndex: 0,
      endIndex: 0,
      totalRows: 100,
      currentPage: 1,
      perPage: 10,
      value2: [],
      vendor: {
        vendor_id: 0,
        name: "",
        email: "",
        mobile: "",
        country: "",
      },
      options: [],
    };
  },
  validations: {
    vendor: {
      name: { required },
      email: { required },
      mobile: { required },
      country: { required },
    },
  },
  computed: {
    joinedValue() {
      return this.value2.join("#"); // 用#连接字符串
    },
  },
  created() {
    this.adminLists(this.currentPage);
    this.GetUserList(this.currentPage);
    this.GetUserListData();
    const userData = JSON.parse(localStorage.getItem('user'));
    if(userData.userId === "609d50b508dc9628fc8933a1"){
        this.isAdmin = true
    }else{
        this.isAdmin = false
    }
  },
  methods: {
    GetUserListData() {
      GetCountryList().then((res) => {
        const allCountry = res.data.rows.map((v) => {
          return {
            value: v.countryName,
            iso_code: v.isoCode.toLowerCase(),
            label: v.countryName,
          };
        });
        this.options = allCountry;
      });
    },

    GetUserList(page) {
      GetUserSearchList({
        userType: 1,
        page: page || this.currentPage,
        pageSize: this.perPage,
      }).then((res) => {
        res.data.rows.forEach((item) => {
          if (item.country.includes("#")) {
            this.value2 = item.country.split("#");
          } else {
            this.value2 = [item.country];
          }
          item.country = item.country.replace(/#/g, " / ");
          item.status = item.status == 0 ? true : false;
          item.createAt = moment(item.createAt).format("MMMM,DD YYYY HH:mm:ss");
          this.vendorsData.push(item);
        });
      });
    },

    submitData() {
      console.log("this.vendor", this.vendor);

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          text: this.$t("content.subscriptions.submitError"),
          confirmButtonText: this.$t("common.ok"),
        });
      } else {
        EditUser({
          country: this.joinedValue,
          userId: this.vendor.vendor_id,
          mobile: this.vendor.mobile
        }).then((res) => {
          this.$swal({
            text: res.errmsg,
          });
          this.$router.go(0);
        });
      }
    },

    deleteVendor(id) {
      this.$swal({
        text: this.$t("common.confirmdelete"),
        confirmButtonText: this.$t("common.ok"),
        cancelButtonText: this.$t("common.cancel"),
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed == true) {
          setStatus("PUT", "vendor", id, 2).then((res) => {
            this.$swal({
              text: res.message,
              confirmButtonText: this.$t("common.ok"),
            });
          });
        }
      });
    },

    adminLists(page) {
      getLists("GET", "admin", page, "desc").then((res) => {
        // this.vendorsData = [];
        res.data.rows.forEach((item) => {
          item.status = item.status == "Active" ? true : false;
          this.vendorsData.push(item);
        });
        //this.vendorsData = res.data.rows;
        this.totalRows = res.data.count;
      });
    },
    setVenderStatus(id, status) {
      EditUser({
        status: status == true ? 0 : 2,
        userId: id,
      }).then((res) => {
        console.log("setVenderStatus", res);
      });
    },

    editVendor(id) {
      this.editVendorModel = true;
      console.log(id);

      for (let i = 0; i < this.vendorsData.length; i++) {
        if (this.vendorsData[i]["userId"] == id) {
          this.vendor.vendor_id = this.vendorsData[i]["userId"];
          this.vendor.name = this.vendorsData[i]["nickName"];
          this.vendor.email = this.vendorsData[i]["email"];
          this.vendor.mobile = this.vendorsData[i]["mobile"];
          this.vendor.country = this.vendorsData[i]["country"];
        }
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onPageChange() {
      // this.startIndex = (this.currentPage - 1) * this.perPage;
      // this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      //   this.vendorLists(this.currentPage);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  width: 100%;
                "
              >
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("common.id") }}</th>
                    <th>{{ $t("common.name") }}</th>
                    <th>{{ $t("common.email") }}</th>
                    <th>{{ $t("common.mobile") }}</th>
                    <th>{{ $t("common.country") }}</th>
                    <th>{{ $t("common.status") }}</th>
                    <th>{{ $t("content.vendors.verified") }}</th>
                    <th>{{ $t("common.createtime") }}</th>
                    <th style="width: 80px">{{ $t("common.action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in vendorsData" :key="index">
                    <td>{{ item.userId }}</td>
                    <td>{{ item.nickName }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.mobile }}</td>
                    <td>{{ item.country }}</td>
                    <td>
                      <b-form-checkbox
                        v-model="item.status"
                        @change="setVenderStatus(item.userId, item.status)"
                        switch
                        class="mb-1"
                      ></b-form-checkbox>
                    </td>
                    <td>{{ item.isDocumentVerified }}</td>
                    <td>{{ item.createAt }}</td>
                    <td v-if="isAdmin">
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editVendor(item.userId)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteVendor(item.userId)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a> -->
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row justify-content-md-between align-items-md-center">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @input="onPageChange"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="editVendorModel"
      :title="$t('content.vendors.edit')"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.name.$model"
            :placeholder="$t('common.name')"
          />
          <span class="error" v-if="!$v.vendor.name.required"
            >* {{ $t("common.namerequired") }}</span
          >
        </div>

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.email.$model"
            :placeholder="$t('common.email')"
          />
          <span class="error" v-if="!$v.vendor.email.required"
            >* {{ $t("common.emailrequired") }}</span
          >
        </div>

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.mobile.$model"
            :placeholder="$t('common.mobile')"
          />
          <span class="error" v-if="!$v.vendor.mobile.required"
            >* {{ $t("common.mobilerequired") }}</span
          >
        </div>

        <div class="form-group">
          <!-- <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.country.$model"
            :placeholder="$t('common.country')"
          /> -->
          <el-select
            v-model="value2"
            multiple
            style="width: 100%"
            :placeholder="$t('common.selectPlaceholder')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$t('categorys.' + item.label)"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span class="error" v-if="value2.length == 0"
            >* {{ $t("common.countryrequired") }}</span
          >
        </div>

        <input type="hidden" v-model="vendor.vendor_id" />
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editVendorModel = false">{{
          $t("common.close")
        }}</b-button>
        <b-button variant="primary" @click="submitData">
          {{ $t("common.submit") }}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <info-list
      title="content.vendors.userinfo"
      v-model="user"
      :names="names"
    ></info-list>
        <h4 class="detail-h4">{{ $t("content.vendors.operationlogs") }}</h4>
    <record-list />
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import InfoList from "@/components/InfoList";
import RecordList from "@/components/RecordList";
import { formatDataTime } from "@/utils";
import { GetUserDetail } from "@/utils/api";
export default {
  components: { Layout, PageHeader, InfoList,RecordList },
  data() {
    return {
      user: {},
      title: this.$t("content.vendors.StoreDetail"),
      items: [
        {
          text: this.$t("content.vendors.storeList"),
        },
        {
          text: this.$t("content.vendors.StoreDetail"),
          active: true,
        },
      ],
    };
  },
  computed: {
    names: function () {
      return this.$t("common");
    },
  },
  created() {
    this.getUserDetail();
  },
  methods: {
    getUserDetail() {
      GetUserDetail({ id: this.$route.query.id })
        .then((res) => {
          console.log("GetUserDetail-res", res);
          const { ...orderData } = res.data;
          const {
            // 用户信息
            userId,
            country,
            img,
            userName,
            nickName,
            email,
            mobile,
            updateAt,
          } = orderData;
          this.user = {
            userId,
            country,
            img,
            userName,
            nickName,
            email,
            mobile,
            updateAt: formatDataTime(updateAt),
          };
        })
        .catch((err) => {
          console.log("GetUserDetail-err", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-h4 {
  color: #333;
  background: transparent;
  border: 1px solid transparent;
  padding: 12px 10px;
  font-size: 18px;
  font-weight: normal;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import ElementUI from 'element-ui';
import ElementLocale from "element-ui/lib/locale";
import moment from 'moment'
import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";
import "element-ui/lib/theme-chalk/index.css";
import htmlToPdf from '@/utils/htmlToPdf'
Vue.use(htmlToPdf)

import axios from 'axios'
Vue.prototype.$axios = axios

import * as utils from "@/utils";
Vue.prototype.$utils = utils;

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(ElementUI);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
ElementLocale.i18n((key, value) => i18n.t(key, value));
Vue.component('apexchart', VueApexCharts)


window.vm = new Vue({
  router,
  store,
  i18n,
  moment,
  render: h => h(App)
}).$mount('#app')

<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      @detail="detail"
      @backflow="backflow"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { GetUserSearchList } from "@/utils/api";
import { formatDataTime, navto } from "@/utils";

export default {
  props: {
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiList },
  data() {
    return {
      tableColumn: [
        {
          name: "userId",
          label: "common.id",
        },
        {
          name: "userName",
          label: "common.name",
        },
        {
          name: "nickName",
          label: "common.nickName",
        },
        {
          name: "email",
          label: "common.email",
        },
        {
          name: "mobile",
          label: "common.mobile",
        },
        {
          name: "country",
          label: "common.country",
        },
        {
          name: "createAt",
          label: "common.createtime",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      tableFun: GetUserSearchList,
      tableFunReq: {
        search: "",
        orderKey: "",
        pageSize: this.max || 10,
      },
      count: 0,
    };
  },
  methods: {
    backflow(count) {
      this.count = count;
    },
    detail(item) {
      console.log("navtodetail:", item);
      navto("/users/userdetail", { id: item.userId });
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>
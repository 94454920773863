import axios from 'axios';

axios.defaults.withCredentials = false;

const service = axios.create({
    baseURL: process.env.VUE_APP_V2_API_URL,
    timeout: 20000
});

service.interceptors.request.use(config => {
    let admin_user_token = localStorage.getItem('admin_user_token');
    config.headers['x-auth-token'] = admin_user_token;
    return config;
}, error => {
    console.log(error);
    return Promise.reject();
});

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log('axios-error:', error);
        if (
            error.response &&
            error.response.status &&
            400 <= error.response.status < 500
          ) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message &&
              error.response.data.message === "Invalid token."
            ) {
              console.log("Invalid token.");
              localStorage.clear();
              window.location.href = "/login";
              return Promise.reject(error);
            }
            return Promise.resolve(error.response.data);
        } else {
            return Promise.reject(error)
        }
    }
);

export default service;

<script>
import { GetAllProductCount } from "@/utils/api";

/**
 * Widget Component
 */
export default {
  data() {
    return {
      statData: [],
    };
  },
  created() {
    GetAllProductCount({})
      .then((lineRes) => {
        this.statData = [
          {
            title: "Product Total",
            icon: "ri-briefcase-4-line",
            value: lineRes.data.allUserOfProduct,
            subvalue: " 20% ",
          },
        ];
      })
      .catch();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
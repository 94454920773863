<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <vendors-list></vendors-list>
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import VendorsList from "@/components/VendorsList";
export default {
  components: { Layout, PageHeader, VendorsList },
  data() {
    return {
      title: this.$t("content.vendors.text"),
      items: [
        {
          text: this.$t("content.vendors.text"),
        },
        {
          text: this.$t("content.vendors.store"),
          active: true,
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.vendor-list {
  font-size: 12px;
}
</style>
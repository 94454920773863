<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import CountrySelect from "@/components/s-ui/form/CountrySelect";
import { getLists, updateData, setStatus } from "../../../helpers/api/api";
import { GetUserSearchList } from "@/utils/api";
import { required } from "vuelidate/lib/validators";
const moment = require("moment");

export default {
  page: {
    title: "Vendors",
  },
  components: {
    Layout,
    PageHeader,
    CountrySelect,
  },
  data() {
    return {
      title: this.$t("content.vendors.text"),
      items: [
        {
          text: this.$t("content.vendors.text"),
        },
        {
          text: this.$t("content.vendors.lists"),
          active: true,
        },
      ],
      vendorsData: [],
      submitted: false,
      showmodal: false,
      editVendorModel: false,
      startIndex: 0,
      endIndex: 0,
      totalRows: 100,
      currentPage: 1,
      perPage: 10,

      vendor: {
        vendor_id: 0,
        name: "",
        email: "",
        mobile: "",
        country: "",
      },

      req: {
        search: "",
        country: "",
        pageSize: 10,
      },
    };
  },
  validations: {
    vendor: {
      name: { required },
      email: { required },
      mobile: { required },
      country: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.vendorsData.length;
    },
  },
  created() {
    this.vendorLists(this.currentPage);
    this._vendorLists(this.currentPage);
  },
  watch: {
    'req.country'() {
      this.vendorLists();
    }
  },
  methods: {
    submitData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          text: this.$t("content.subscriptions.submitError"),
          confirmButtonText: this.$t("common.ok"),
        });
      } else {
        updateData("PUT", "vendor", this.vendor.vendor_id, {
          name: this.vendor.name,
          email: this.vendor.email,
          mobile: this.vendor.mobile,
          country: this.vendor.country,
        }).then((res) => {
          this.$swal({
            text: res.message,
          });

          this.vendorLists(this.currentPage);
        });
      }
    },

    deleteVendor(id) {
      this.$swal({
        text: this.$t("common.confirmdelete"),
        confirmButtonText: this.$t("common.ok"),
        cancelButtonText: this.$t("common.cancel"),
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed == true) {
          setStatus("PUT", "vendor", id, 2).then((res) => {
            this.$swal({
              text: res.message,
              confirmButtonText: this.$t("common.ok"),
            });

            this.vendorLists(this.currentPage);
          });
        }
      });
    },

    onSubmit(e) {
      console.log('onSubmit:', e);
      this.vendorLists();
    },

    vendorLists(page, desc, options) {
      GetUserSearchList({
        page: page || this.currentPage,
        // desc: desc || this.desc,
        // orderKey: "order_view",
        ...options,
        ...this.req,
      })
        .then((res) => {
          console.log("tableFun-res:", res);
          this.vendorsData = [];
          console.log(res.data);
          res.data.rows.forEach((item) => {
            item.status = item.status === 0;
            item.createdAt = moment(item.CreatedAt).format(
              "MMMM,DD YYYY HH:mm:ss"
            );
            this.vendorsData.push(item);
          });
          //this.vendorsData = res.data.rows;
          this.totalRows = res.data.count;
        })
        .catch((err) => {
          console.log("tableFun-err:", err);
        });
    },

    _vendorLists(page) {
      getLists("GET", "vendor", page, "desc").then((res) => {
        // this.vendorsData = [];
        console.log(res.data);
        // res.data.rows.forEach((item) => {
        //   item.status = item.status == "Active" ? true : false;
        //   item.createdAt = moment(item.createdAt).format(
        //     "MMMM,DD YYYY HH:mm:ss"
        //   );
        //   this.vendorsData.push(item);
        // });
        // //this.vendorsData = res.data.rows;
        // this.totalRows = res.data.count;
      });
    },
    setVenderStatus(id, status) {
      var set_status = status == true ? 0 : 1;
      setStatus("PUT", "vendor", id, set_status).then();
    },

    editVendor(id) {
      this.editVendorModel = true;
      for (let i = 0; i < this.vendorsData.length; i++) {
        if (this.vendorsData[i]["_id"] == id) {
          this.vendor.vendor_id = this.vendorsData[i]["_id"];
          this.vendor.name = this.vendorsData[i]["name"];
          this.vendor.email = this.vendorsData[i]["email"];
          this.vendor.mobile = this.vendorsData[i]["mobile"];
          this.vendor.country = this.vendorsData[i]["country"];
        }
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onPageChange() {
      // this.startIndex = (this.currentPage - 1) * this.perPage;
      // this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      this.vendorLists(this.currentPage);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">
              <div class="row">
                <div class="col-md-6"></div>

                <div class="col-md-6 flexright">
                  <country-select v-model="req.country" />
                  <div class="form-inline float-md-right">
                    <div class="search-box ml-2">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control rounded"
                          v-model.trim="req.search"
                          :placeholder="$t('common.search')"
                          @keyup.enter="onSubmit"
                        />
                        <i class="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  width: 100%;
                  margin-top: 10px;
                "
              >
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("common.id") }}</th>
                    <th>{{ $t("common.name") }}</th>
                    <th>{{ $t("common.email") }}</th>
                    <th>{{ $t("common.mobile") }}</th>
                    <th>{{ $t("common.country") }}</th>
                    <th>{{ $t("common.status") }}</th>
                    <!-- <th>{{ $t("content.vendors.verified") }}</th> -->
                    <th>{{ $t("common.createtime") }}</th>
                    <th style="width: 80px">{{ $t("common.action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in vendorsData" :key="index">
                    <td>{{ item.userId }}</td>
                    <td>{{ item.userName }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.mobile }}</td>
                    <td>{{ item.country }}</td>
                    <td>
                      <b-form-checkbox
                        v-model="item.status"
                        @change="setVenderStatus(item.ID, item.status)"
                        switch
                        class="mb-1"
                      ></b-form-checkbox>
                    </td>
                    <!-- <td>{{ item.isDocumentVerified }}</td> -->
                    <td>{{ item.createdAt }}</td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editVendor(item.ID)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteVendor(item.ID)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row justify-content-md-between align-items-md-center">
                <div class="col">
                  <div
                    class="
                      dataTables_paginate
                      paging_simple_numbers
                      float-right
                    "
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @input="onPageChange"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="editVendorModel"
      :title="$t('content.vendors.edit')"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.name.$model"
            :placeholder="$t('common.name')"
          />
          <span class="error" v-if="!$v.vendor.name.required"
            >* {{ $t("common.namerequired") }}</span
          >
        </div>

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.email.$model"
            :placeholder="$t('common.email')"
          />
          <span class="error" v-if="!$v.vendor.email.required"
            >* {{ $t("common.emailrequired") }}</span
          >
        </div>

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.mobile.$model"
            :placeholder="$t('common.mobile')"
          />
          <span class="error" v-if="!$v.vendor.mobile.required"
            >* {{ $t("common.mobilerequired") }}</span
          >
        </div>

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="$v.vendor.country.$model"
            :placeholder="$t('common.country')"
          />
          <span class="error" v-if="!$v.vendor.country.required"
            >* {{ $t("common.countryrequired") }}</span
          >
        </div>

        <input type="hidden" v-model="vendor.vendor_id" />
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editVendorModel = false">{{
          $t("common.close")
        }}</b-button>
        <b-button variant="primary" @click="submitData">
          {{ $t("common.submit") }}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.flexright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <user-list></user-list>
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import UserList from "@/components/UserList";
export default {
  components: { Layout, PageHeader, UserList },
  data() {
    return {
      title: this.$t("content.users.text"),
      items: [
        {
          text: this.$t("content.users.text"),
        },
        {
          text: this.$t("content.users.lists"),
          active: true,
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.vendor-list {
  font-size: 12px;
}
</style>
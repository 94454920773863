<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Product by Category</h4>
      <div v-if="lineData.length">
        <div id="myChartEach" :style="{ height: '540px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCategroyProductCount } from "@/utils/api";
export default {
  data() {
    return {
      lineData: [],
    };
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            color: [
              "#37A2DA",
              "#32C5E9",
              "#67E0E3",
              "#9FE6B8",
              "#FFDB5C",
              "#ff9f7f",
              "#fb7293",
              "#E062AE",
              "#E690D1",
              "#e7bcf3",
              "#9d96f5",
              "#8378EA",
              "#96BFFF",
            ],
            name: "Category",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.lineData,
          },
        ],
      };
    },
  },
  mounted() {
    GetCategroyProductCount({})
      .then((res) => {
        this.lineData = res.data.rows.map((v) => {
          return {
            value: v.userOfProductCount || 0,
            name: v.categoryName || "",
          };
        });
        this.$nextTick(() => {
          this.drawLine();
        });
      })
      .catch();
  },
  methods: {
    drawLine() {
      // var i=0;
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChartEach"));
      myChart.setOption(this.option);
    },
  },
};
</script>

<style>
</style>
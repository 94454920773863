<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists} from "../../../helpers/api/api";
import { required} from 'vuelidate/lib/validators';
const moment = require('moment')

export default {
  page: {
      title: 'Inquiry'
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: this.$t('content.inquiry.text'),
      items: [
        {
          text: this.$t('content.inquiry.text')
        },
        {
          text: this.$t('content.inquiry.lists'),
          active: true
        }
      ],
      data: [],
      submitted: false,
      editModal: false,
      formTitle: '',
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      brand_id: '',
      brandName: '',
      image: ''
    };
  },
  validations: {
      brandName: {required}
  },
  created() {
     this.inquiryLists(this.currentPage)
  },
  methods: {
    inquiryLists(page) {
        getLists('GET', 'inquiry', page, 'desc').then(res => {
            this.data = [];

            console.log(res.data)

            res.data.rows.forEach(item => {
                item.status = item.status == 'Active' ? true : false;
                item.updatedAt = moment(item.updatedAt).format('MMMM,DD YYYY HH:mm:ss');
                this.data.push(item);
            })
            this.totalRows = res.data.count;
        })
    },
    onPageChange() {
        this.inquiryLists(this.currentPage);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">

                    <table
                        class="table table-centered datatable dt-responsive nowrap"
                        style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                    >
                        <thead class="thead-light">
                        <tr>
                            <th>{{$t('common.id')}}</th>
                            <th>{{$t('common.name')}}</th>
                            <th>{{$t('common.user')}}</th>
                            <th>{{$t('common.updatetime')}}</th>
                            <th>{{$t('common.action')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in data" :key="index">
                            <td>{{item._id}}</td>
                            <td>{{item.productId.productName}}</td>
                            <td>{{item.userId}}</td>
                            <td>{{item.updatedAt}}</td>
                            <td>

                            </td>
                        </tr>
                        </tbody>
                    </table>


                </div>

                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="onPageChange"></b-pagination>
                        </ul>
                        </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="vertical"
    @select="handleSelect"
  >
    <!-- category -->
    <el-menu-item index="0">
      <template v-slot:title>
        <span>{{ $t("common.all") }}</span>
      </template>
    </el-menu-item>
    <el-menu-item
      v-for="(item, index) in list"
      :key="`category_${item.id}_${index}`"
      :index="`${item.id}`"
    >
      <template v-slot:title>
        <span>{{ item.title }}</span>
      </template>
    </el-menu-item>
  </el-menu>
</template>
<script>
import { GetCategoryList } from "@/utils/api";

export default {
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeIndex: "0",
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      GetCategoryList({
        type: this.type,
      })
        .then((res) => {
          console.log("GetCategoryList-res:", res);
          this.list = res.data.rows;
        })
        .catch((err) => {
          console.log("GetCategoryList-err:", err);
        });
    },
    // 分类选择
    handleSelect(e) {
      this.activeIndex = e;
      this.$emit("change", e);
    },
  },
};
</script>

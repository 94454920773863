<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      :refactor="tableDataRefactor"
      @backflow="backflow"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { GetSpuList } from "@/utils/api";
import { formatDataTime } from "@/utils";

export default {
  props: {
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiList },
  data() {
    return {
      tableColumn: [
        {
          type: "image",
          name: "img",
          label: "content.order.productImg", 
        },
        {
          name: "spuName",
          label: "content.order.productName",
        },
        {
          type: "price-fixed",
          name: "minPrice",
          label: "content.order.minprice",
          width: 100,
        },
        {
          type: "price-fixed",
          name: "maxPrice",
          label: "content.order.maxprice",
          width: 100,
        },
        {
          name: "categoryNameList",
          label: "content.order.category",
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      tableFun: GetSpuList,
      tableFunReq: {
        storeId: this.$route.query.id,
        orderKey: "",
        pageSize: this.max || 10,
      },
      tableDataRefactor: (list) => {
        return list.map((v) => {
          return {
            ...v,
            categoryNameList: v.categoryList
              .map((c) => c.categoryName)
              .join(" -> "),
          };
        });
      },    
      count: 0,
    };
  },
  methods: {
    backflow(count) {
      this.count = count;
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>
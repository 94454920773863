<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import {getLists} from "../../../helpers/api/api"
const moment = require("moment");
import { GetOrderList } from "@/utils/api";

/**
 * Orders Component
 */
export default {
  page: {
    title: "Orders",
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: this.$t("content.orders.text"),
      items: [
        {
          text: this.$t("content.orders.text"),
        },
        {
          text: this.$t("content.orders.lists"),
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      viewModel: false,
      order: [],
      orderProductData: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.ordersData.length;
    },
  },
  mounted() {
    this.orderLists(this.currentPage);
  },
  methods: {
    // orderLists(page) {
    //   getLists('POST', 'order', page, 'desc').then(res => {
    //     console.log('3333333',res.data);

    //       this.ordersData = []
    //       res.data.rows.forEach(item => {
    //           item.createdAt = moment(item.createdAt).format('MMMM,DD YYYY HH:mm:ss');
    //           this.ordersData.push(item)
    //       });
    //       this.totalRows = res.data.count;
    //   });
    // },

    orderLists(page) {
      GetOrderList({ page }).then((res) => {
        this.ordersData = [];
        res.data.rows.forEach((item) => {
          item.createdAt = moment(item.createdAt).format(
            "MMMM,DD YYYY HH:mm:ss"
          );
          this.ordersData.push(item);
        });
        this.totalRows = res.data.count;
      });
    },

    viewOrder(id) {
      this.viewModel = true;
      this.orderProductData = this.ordersData.find((item) => item.id == id);
      console.log(this.orderProductData,'this.orderProductData');
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onPageChange() {
      this.orderLists(this.currentPage);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">{{
                    $t("content.orders.allorder")
                  }}</a>
                </template>

                <div class="table-responsive">
                  <table
                    class="table table-centered datatable dt-responsive nowrap"
                    style="
                      border-collapse: collapse;
                      border-spacing: 0;
                      width: 100%;
                    "
                  >
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("common.id") }}</th>
                        <th>{{ $t("content.orders.orderno") }}</th>
                        <th>{{ $t("content.orders.subtotal") }}</th>
                        <th>{{ $t("common.user") }}</th>
                        <th>{{ $t("common.status") }}</th>
                        <th>{{ $t("common.createtime") }}</th>
                        <th>{{ $t("common.action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in ordersData" :key="index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.orderNo }}</td>
                        <td>{{ item.orderList[0].price }}</td>
                        <td>{{ item.userName }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.createdAt }}</td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            class="mr-3 text-primary"
                            v-b-tooltip.hover
                            :title="$t('common.view')"
                            @click="viewOrder(item.id)"
                          >
                            <i class="mdi mdi-eye font-size-18"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          @input="onPageChange"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="lg"
      v-model="viewModel"
      :title="$t('common.view')"
      centered
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="table-responsive" style="width: 100%">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th>{{ $t("content.orders.product") }}</th>
              <th>&nbsp;</th>
              <th>{{ $t("common.quantity") }}</th>
              <th>{{ $t("common.status") }}</th>
              <!-- <th>{{$t('common.action')}}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in orderProductData" :key="index">
              <td style="width: 50px">
                <img :src="item.productImg" style="width: 50px" />
              </td>
              <td width="250px">
                {{ item.productName }}
              </td>
              <td>{{ item.total }}</td>
              <td>{{ item.status }}</td>
              <!-- <td>

                          <a
                            href="javascript:void(0);"
                            class="mr-3 text-primary"
                            v-b-tooltip.hover
                            :title="$t('common.readytoship')"
                            @click="toship(item._id)"
                          >
                            <i class="ri-ship-2-fill font-size-18"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-danger"
                            v-b-tooltip.hover
                            :title="$t('common.cancel')"
                            @click="cancel(item._id)"
                          >
                            <i class="mdi mdi-trash-can font-size-18"></i>
                          </a>


                        </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="viewModel = false">{{
          $t("common.close")
        }}</b-button>
      </template>
    </b-modal>
  </Layout>
</template>
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  methods: {
    autoChange() {
      console.log("navigator.language:", navigator.language);

      var setlanguage = localStorage.getItem("setlanguage");
      if (setlanguage == "en" || setlanguage == "zh") {
        this.$i18n.locale = setlanguage;
      } else {
        switch (navigator.language) {
          case "en":
            this.$i18n.locale = "en";
            break;
          case "zh":
          case "zh-CN":
          case "zh-TW":
          case "zh-HK":
            this.$i18n.locale = "zh";
            break;
        }
      }
    },
  },
  created() {
    this.autoChange();
  }
};
</script>

<style>
.imgbg {
  background-image: url("http://oss.sokogate.com/static/photoshop_background.png");
}
.el-row--flex {
  flex-wrap: wrap;
}
</style>
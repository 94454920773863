<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <order-list />
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import OrderList from "@/components/OrderList";
export default {
  components: {
    Layout,
    PageHeader,
    OrderList
  },
  data() {
    return {
      title: this.$t("menuitems.order.list"),
      items: [
        {
          text: this.$t("menuitems.order.management"),
        },
        {
          text: this.$t("menuitems.order.list"),
          active: true,
        },
      ],
    };
  },
};
</script>

<style>
</style>
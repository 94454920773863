<template>
  <div>
    <div>
      <el-input
        :placeholder="$t('common.editorcontent')"
        v-model="input3"
        v-if="isSearch"
        class="input-with-select"
        :clearable="true"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchOrderInfo"
        ></el-button>
      </el-input>
      <el-button-group style="margin-left: 50px" v-if="isSearch">
        <el-button type="warning" @click="payStatusButt(101)">{{
          $t("content.order.payStatusText.101")
        }}</el-button>
        <el-button type="success" @click="payStatusButt(301)">{{
          $t("content.order.payStatusText.301")
        }}</el-button>
      </el-button-group>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      ref="list"
      :max-height="maxHeight"
    >
      <el-table-column v-if="selection" type="selection" width="55">
      </el-table-column>
      <!-- 未同步 -->
      <el-table-column
        label-class-name="hd-row"
        v-for="(item, index) in column"
        :key="`column-${index}`"
        :prop="item.name"
        :label="$t(item.label)"
        :width="item.width"
      >
        <template v-if="item.type === 'link'" v-slot:default="scope">
          <a
            :href="`${item.detail.link}${scope.row[item.detail.key]}`"
            target="_blank"
            >{{ scope.row[item.name] }}
          </a>
        </template>
        <template v-else-if="item.type === 'image'" v-slot:default="scope">
          <el-image
            :src="`${getCoverImage(
              scope.row[item.name]
            )}?x-oss-process=style/w128`"
            :preview-src-list="[getCoverImage(scope.row[item.name])]"
            class="imgbg"
            fit="contain"
          ></el-image>
        </template>
        <template
          v-else-if="item.type === 'price-fixed'"
          v-slot:default="scope"
        >
          <price
            v-model="scope.row[item.name]"
            :to="scope.row.currencyTo"
            :isPaid="scope.row.isPaid"
          />
        </template>
        <template v-else-if="item.type === 'switch'" v-slot:default="scope">
          <el-switch
            v-model="scope.row[item.name]"
            :active-value="1"
            :inactive-value="0"
            active-color="#ffafaf"
            inactive-color="#13ce66"
            :active-text="
              $t(scope.row[item.name] === 1 ? 'common.hidden' : 'common.shown')
            "
            @change="switchChange($event, scope.row, scope.$index)"
          >
          </el-switch>
        </template>
        <template v-else-if="item.range" v-slot:default="scope">
          {{ $t(item.range[scope.row[item.name]]) }}
        </template>
        <!-- 未同步 -->
        <template v-else-if="item.formatFn" v-slot:default="scope">
          {{ item.formatFn(scope.row[item.name]) }}
        </template>
        <template v-else-if="item.status" v-slot:default="scope">
          {{ item.status[scope.row[item.name]] }}
        </template>
        <template v-else-if="item.payMethod" v-slot:default="scope">
          {{ item.payMethod[scope.row[item.name]] }}
        </template>
        <template v-else-if="item.payStatus" v-slot:default="scope">
          {{ item.payStatus[scope.row[item.name]] }}
        </template>

        <!-- 状态字段使用 el-select -->
        <template
          v-else-if="item.name === 'storeStatus'"
          v-slot:default="scope"
        >
          <el-select
            v-model="scope.row[item.name]"
            :placeholder="$t('common.status')"
            @change="statusChange(scope.row)"
            size="medium"
            :clearable="true"
          >
            <el-option
              v-for="(status, index) in nameList"
              :key="index"
              :label="$t(`common.${status.label}`)"
              :value="status"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showOpt"
        label-class-name="hd-row"
        fixed="right"
        :label="$t('common.operation')"
        width="150"
      >
        <template v-slot:default="scope">
          <div style="display: flex">
            <el-button
              v-if="showDetail"
              @click="detail(scope.row)"
              type="text"
              size="small"
            >
              {{ $t("common.detail") }}
            </el-button>

            <el-button
              v-if="showEdit"
              @click="edit(scope.row)"
              type="text"
              size="small"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              v-if="showMoveUp"
              :disabled="scope.$index === 0"
              @click="moveup(scope.row)"
              type="text"
              size="small"
            >
              {{ $t("common.moveup") }}
            </el-button>
            <el-button
              v-if="showMoveDown"
              :disabled="scope.$index === list.length - 1"
              @click="movedown(scope.row)"
              type="text"
              size="small"
            >
              {{ $t("common.movedown") }}
            </el-button>
            <el-popconfirm
              v-if="showDel"
              :title="$t('common.confirmDelete')"
              style="margin-left: 10px"
              @confirm="del(scope.row)"
            >
              <template v-slot:reference>
                <el-button type="text" size="small">{{
                  $t("common.delete")
                }}</el-button>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <template v-if="req">
      <el-pagination
        ref="pagination"
        background
        class="list-pagination"
        layout="prev, pager, next,jumper"
        :hide-on-single-page="true"
        :page-size="req.pageSize"
        :total="total"
        :current-page.sync="page"
        @current-change="currentChange"
      >
      </el-pagination>
    </template>
  </div>
</template>
<script>
import Price from "@/components/s-ui/info/price.vue";
import { EditStorebyAdmin } from "@/utils/api";
export default {
  components: { Price },
  props: {
    column: {
      type: Array,
      default() {
        return [];
      },
    },
    fun: {
      type: Function,
    },
    refactor: {
      type: Function,
    },
    req: {
      type: Object,
      value: {},
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isOrderView: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    defaultData: {
      type: Array,
      default() {
        return [];
      },
    },
    maxHeight: String,
  },
  data() {
    return {
      input3: "",
      list: [],
      count: 0,
      page: 1,
      desc: 0,
      options: {},
      abc: false,
      orderListStatus: 0,
      nameList: [
        {
          value: "1",
          label: "Confirmed",
        },
        {
          value: "2",
          label: "Suspended",
        },
        {
          value: "3",
          label: "Resume",
        },
        {
          value: "4",
          label: "Terminated",
        },
        {
          value: "5",
          label: "Cancelled",
        },
      ],
      value: "",
    };
  },
  created() {
    this.getList();
  },
  computed: {
    total() {
      return this.count; // Math.ceil(this.count / this.req.pageSize);
    },
    showOpt() {
      const showOpt = this.showDetail || this.showEdit || this.showDel;
      return !!showOpt;
    },
    showDetail() {
      return !!this.$listeners["detail"];
    },
    showManagement() {
      return !!this.$listeners["management"];
    },
    showEdit() {
      return !!this.$listeners["edit"];
    },
    showDel() {
      return !!this.$listeners["del"];
    },
    showMoveUp() {
      return !!this.$listeners["moveup"];
    },
    showMoveDown() {
      return !!this.$listeners["movedown"];
    },
  },
  methods: {
    searchOrderInfo() {
      if (this.input3) {
        this.orderListStatus = 0;
        this.$route.query.page = 1;
        this.page = 1;
        this.getList();
      } else {
        this.orderListStatus = 0;
        this.input3 = "";
        this.getList();
      }
    },
    payStatusButt(status) {
      this.orderListStatus = status;
      this.input3 = "";
      this.getList();
    },
    statusChange(item) {
      console.log(item);
      let EditStoreInfo = {
        id: item.storeId,
        status: Number(item.storeStatus.value),
      };
      EditStorebyAdmin(EditStoreInfo)
        .then((res) => {
          console.log("000000000", res);
          this.$message({
            type: "success",
            message: this.$t("common.successfullySet"),
          });
        })
        .catch((err) => {
          console.log("111111111", err);
        });
    },
    setList(list) {
      this.list = list;
    },
    getList(page, desc, options) {
      if (this.defaultData.length) {
        this.defaultData.forEach((item) => {
          if (item.wayMode === 101) {
            item.productName = "By Ship";
            item.pcs = 1;
            item.price = item.total;
            item.productImg =
              "https://img1.baidu.com/it/u=2805274674,3550462585&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1737133200&t=2b666d315f4d989b6122ba8c648a87e6";
          } else if (item.wayMode === 201) {
            item.productName = "By Air";
            item.pcs = 1;
            item.price = item.total;
            item.productImg =
              "https://img0.baidu.com/it/u=108508610,799796520&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1737133200&t=57946e1b39f283c0bef314fd2e30dc4a";
          } else if(item.wayMode === 201){
            item.productName = "Free Shipping";
            item.pcs = 1;
            item.price = item.total;
          }
        });
        console.log("getlist:", this.defaultData);
        this.list = this.defaultData;
      } else {
        if (options) {
          this.options = options;
        }
        if (!page && this.$route.query.page) {
          this.page = Number(this.$route.query.page);
        }
        this.fun &&
          this.fun({
            page: page || this.page,
            desc: desc || this.desc,
            status: this.orderListStatus ? this.orderListStatus : 0,
            outTradeNo: this.input3 ? this.input3 : "",
            orderKey: this.isOrderView ? "order_view" : "",
            ...(options || this.options),
            ...this.req,
          })
            .then((res) => {
              const formattedRows = res.data.rows.map((item) => {
                if (item.bloggerId && item.number == 0) {
                  item.number = this.$t("common.UnlimitedFrequency");
                }
                if (item.discount) {
                  item.discount = item.discount + "%";
                  item.commission = item.commission + "%";
                }

                if (item.orderList) {
                  if (item.orderList[0].status == 101) {
                    item.isPaid = false;
                  } else if (
                    item.orderList[0].status == 401 ||
                    item.orderList[0].status == 301
                  ) {
                    item.isPaid = true;
                  }
                  return {
                    ...item,
                  };
                } else {
                  let store = item.store ?? {};
                  if (!("status" in store)) {
                    store = { ...store, status: 0 };
                  }
                  if (store.status === 0) {
                    item.storeStatus = this.$t("common.inReview");
                    //   item.store.status = this.$t("common.inReview");
                  } else if (store.status === 1) {
                    item.storeStatus = this.$t("common.Confirmed");
                    //   item.store.status = this.$t("common.Confirmed");
                  } else if (store.status === 2) {
                    item.storeStatus = this.$t("common.Suspended");
                    //   item.store.status = this.$t("common.Suspended");
                  } else if (store.status === 3) {
                    item.storeStatus = this.$t("common.Resume");
                    //   item.store.status = this.$t("common.Resume");
                  } else if (store.status === 4) {
                    item.storeStatus = this.$t("common.Terminated");
                    //   item.store.status = this.$t("common.Terminated");
                  } else if (store.status === 5) {
                    item.storeStatus = this.$t("common.Cancelled");
                    //   item.store.status = this.$t("common.Cancelled");
                  }
                  return {
                    ...item,
                    store,
                  };
                }
              });
              console.log("000000000000", formattedRows);

              this.list = this.refactor
                ? this.refactor(formattedRows)
                : formattedRows;
              this.count = res.data.count;
              this.$emit("backflow", res.data.count || 0);
            })
            .catch((err) => {
              console.log("tableFun-err:", err);
            });
      }
    },
    getCoverImage(image) {
      // console.log('getCoverImage:', image);
      if (typeof image === "string") {
        return image;
      } else if (image instanceof Array) {
        if (typeof image[0] === "string") {
          return image[0];
        } else if (image[0] instanceof Object) {
          return image[0].img || "";
        }
      }
    },
    detail(row) {
      this.$emit("detail", row);
    },
    edit(row) {
      this.$emit("edit", row);
    },
    moveup(row) {
      this.$emit("moveup", row);
    },
    movedown(row) {
      this.$emit("movedown", row);
    },
    del(row) {
      this.$emit("del", row);
    },
    switchChange(e, row, index) {
      this.$emit("hidden", e, row, index);
    },
    currentChange(page) {
      console.log("currentChange-page:", page);
      this.$router.push({
        path: "",
        query: { page },
      });
      this.page = page;
      this.getList(page);
    },
    handleSelectionChange(val) {
      console.log("handleSelectionChange-val:", val);
      this.$emit("select", val);
    },
    clearSelection() {
      this.$refs["list"].clearSelection();
    },
  },
};
</script>

<style>
.imgbg {
  width: 100px;
  height: 100px;
}
.list-pagination {
  margin-top: 10px;
  text-align: right;
}
.input-with-select {
  width: 60%;
  margin-left: 10px;
  margin-bottom: 20px;
}
</style>
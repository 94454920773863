<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">New Product</h4>
      <div v-if="lineData.length && lineTitle.length">
        <div id="myChart" :style="{ height: '340px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMonthProductCount } from "@/utils/api";
export default {
  data() {
    return {
      lineTitle: [],
      lineData: [],
    };
  },
  computed: {
    option() {
      return {
        color: ["#5664d2"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.lineTitle,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Product",
            type: "bar",
            barWidth: "60%",
            data: this.lineData,
          },
        ],
      };
    },
  },
  mounted() {
    GetMonthProductCount({})
      .then((res) => {
        this.lineTitle = res.data.Month;
        this.lineData = res.data.MonthNum;
        this.$nextTick(() => {
          this.drawLine();
        });
      })
      .catch();
  },
  methods: {
    drawLine() {
      // var i=0;
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption(this.option);
    },
  },
};
</script>

<style>
</style>
<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import Multiselect from "vue-multiselect";

export default {
    page: {
        title: 'Slider Images',
    },
    components: {
        Layout,
        PageHeader,
        Multiselect
    },
    data() {
        return {
            title: 'Gallery',
            items: [
                {
                    text: 'Slider Images'
                },
                {
                    text: 'Lists',
                    active: true
                }
            ],
            createModel: false,
            options: [
                "Alaska",
                "Hawaii",
                "California",
                "Nevada",
                "Oregon",
                "Washington",
                "Arizona",
                "Colorado",
                "Idaho",
                "Montana",
                "Nebraska",
                "New Mexico",
                "North Dakota",
                "Utah",
                "Wyoming",
                "Alabama",
                "Arkansas",
                "Illinois",
                "Iowa"
            ],
            value1: ''
        }
    },
    methods: {
        submitData() {

        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div>

                        <div class="row">
                            <div class="col-md-6">
                            <div class="product-title">
                                <h5>Slider Images</h5>
                            </div>
                            </div>

                            <div class="col-md-6">
                                <button class="btn btn-primary float-right" @click="createModel=true">Upload Images</button>
                            </div>
                        </div>

                        <div class="row no-gutters">
                            <div class="col-xl-4 col-sm-6">
                                <div class="product-box">
                                    <div class="product-img">
                                    
                                    <div class="product-view">
                                        <a href="#">
                                        <i class="ri-delete-bin-line"></i>
                                        </a>
                                    </div>
                                    <img
                                        src="@/assets/images/product/img-1.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                    />
                                    </div>

                                    <div class="text-center">
                                    <h5 class="font-size-15">
                                        <a href="#" class="text-dark">Full sleeve T-shirt</a>
                                    </h5>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-4 col-sm-6">
                                <div class="product-box">
                                    <div class="product-img">
                                    <div class="product-view">
                                        <a href="#">
                                        <i class="ri-delete-bin-line"></i>
                                        </a>
                                    </div>


                                    <img
                                        src="@/assets/images/product/img-1.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                    />
                                    </div>

                                    <div class="text-center">
                                    <h5 class="font-size-15">
                                        <a href="#" class="text-dark">Full sleeve T-shirt</a>
                                    </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-sm-6">
                                <div class="product-box">
                                    <div class="product-img">
                                    <div class="product-view">
                                        <a href="#">
                                        <i class="ri-delete-bin-line"></i>
                                        </a>
                                    </div>


                                    <img
                                        src="@/assets/images/product/img-1.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                    />
                                    </div>

                                    <div class="text-center">
                                    <h5 class="font-size-15">
                                        <a href="#" class="text-dark">Full sleeve T-shirt</a>
                                    </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-sm-6">
                                <div class="product-box">
                                    <div class="product-img">
                                    
                                    <div class="product-view">
                                        <a href="#">
                                        <i class="ri-delete-bin-line"></i>
                                        </a>
                                    </div>

                                    <img
                                        src="@/assets/images/product/img-1.png"
                                        alt
                                        class="img-fluid mx-auto d-block"
                                    />
                                    </div>

                                    <div class="text-center">
                                    <h5 class="font-size-15">
                                        <a href="#" class="text-dark">Full sleeve T-shirt</a>
                                    </h5>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="createModel" title="Update Images" centered>
            <form>

                <div class="form-group">
                    <multiselect v-model="value1" :options="options" :multiple="true"></multiselect>
                </div>

            </form>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="createModel = false">Close</b-button>
                <b-button variant="primary" @click="submitData">
                Submit
                <i class="fab fa-telegram-plane ml-1"></i>
                </b-button>
            </template>
        </b-modal>

    </Layout>
</template>
<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import Multiselect from 'vue-multiselect';

export default {
    page: {
        title: 'Featured Products'
    },
    components: {
        Layout,
        PageHeader,
        Multiselect
    },
    data() {
        return {
            title: 'Featured Products',
            items: [
                {
                    text: 'Featured Products'
                },
                {
                    text: 'Lists',
                    active: true
                }
            ],
            editModel: false,
            categories: [
                "Beauty",
                "Appliances",
                "Books"
            ],
            category: '',
            products: [
                "lo Neck Black T-Shirt",
                "Pad Air (4th Gen) 256 GB ROM 10.9...",
                "E iPhone XS (Gold, 64 GB)"
            ],
            product: ''
        }
    },
    methods: {

    }
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-xl-3 col-lg-4">
                <div class="card">
                <div class="card-header bg-transparent border-bottom">
                    <h5 class="mb-0">Categories  
                        <button class="btn-add" @click="editModel = true"><i class="ri-add-line"></i>Update</button>
                    </h5>
                </div>

                <div class="card-body">
                    <div id="accordion" class="custom-accordion categories-accordion mb-3">
                        <div class="categories-group-card">
                            <a class="categories-group-list" href="#">
                                <i class="ri-star-s-fill font-size-16 align-middle mr-2"></i> Electronic
                            </a>
                        </div>

                        <div class="categories-group-card">
                            <a class="categories-group-list" href="#">
                                <i class="ri-star-s-fill font-size-16 align-middle mr-2"></i> Fashion
                            </a>
                        </div>

                    </div>
                </div>


                </div>
            </div>
            <div class="col-lg-9">
                <div class="card">
                <div class="card-body">
                    <div>
                    <div class="row">
                        <div class="col-md-6">
                        <div class="product-title">
                            <h5>Appliances</h5>
                        </div>
                        </div>

                        <div class="col-md-6">
                        
                        </div>
                    </div>

                    <div class="row no-gutters">
                        <div class="col-xl-4 col-sm-6">
                        <div class="product-box">
                            <div class="product-img">
                            <div class="product-ribbon badge badge-primary">Active</div>
                            <div class="product-view">
                                <a href="#">
                                <i class="ri-delete-bin-line"></i>
                                </a>
                            </div>


                            <img
                                src="@/assets/images/product/img-1.png"
                                alt
                                class="img-fluid mx-auto d-block"
                            />
                            </div>

                            <div class="text-center">
                            <p class="font-size-12 mb-1">Blue color, T-shirt</p>
                            <h5 class="font-size-15">
                                <a href="#" class="text-dark">Full sleeve T-shirt</a>
                            </h5>

                            </div>
                        </div>
                        </div>


                        <div class="col-xl-4 col-sm-6">
                        <div class="product-box">
                            <div class="product-img">
                            
                            <div class="product-view">
                                <a href="#">
                                <i class="ri-delete-bin-line"></i>
                                </a>
                            </div>


                            <img
                                src="@/assets/images/product/img-1.png"
                                alt
                                class="img-fluid mx-auto d-block"
                            />
                            </div>

                            <div class="text-center">
                            <p class="font-size-12 mb-1">Blue color, T-shirt</p>
                            <h5 class="font-size-15">
                                <a href="#" class="text-dark">Full sleeve T-shirt</a>
                            </h5>

                            </div>
                        </div>
                        </div>
                        <div class="col-xl-4 col-sm-6">
                        <div class="product-box">
                            <div class="product-img">
                            
                            <div class="product-view">
                                <a href="#">
                                <i class="ri-delete-bin-line"></i>
                                </a>
                            </div>


                            <img
                                src="@/assets/images/product/img-1.png"
                                alt
                                class="img-fluid mx-auto d-block"
                            />
                            </div>

                            <div class="text-center">
                            <p class="font-size-12 mb-1">Blue color, T-shirt</p>
                            <h5 class="font-size-15">
                                <a href="#" class="text-dark">Full sleeve T-shirt</a>
                            </h5>

                            </div>
                        </div>
                        </div>
                        
                    </div>

                    
                    </div>
                </div>
                </div>
            </div>
            <!-- end row -->
        </div>

        <b-modal v-model="editModel" title="Edit Featured Products" centered>
            <form>
                <div class="form-group">
                    <multiselect v-model="category" :options="categories" :multiple="true"></multiselect>
                </div>
                <div class="form-group">
                    <multiselect v-model="product" :options="products" :multiple="true"></multiselect>
                </div>
            </form>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="editModel = false">Close</b-button>
                <b-button variant="primary" @click="submitData">
                Submit
                <i class="fab fa-telegram-plane ml-1"></i>
                </b-button>
            </template>
        </b-modal>

    </Layout>
</template>
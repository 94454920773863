<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {getLists, getData, updateData, setStatus, createData} from "../../../helpers/api/api";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const moment = require('moment')
const upload = require("../../../helpers/image-upload");

export default {
  page: {
      title: "Blogs",
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component
  },
  data() {
    return {
      title: this.$t('content.blog.text'),
      items: [
        {
          text: this.$t('content.blog.text')
        },
        {
          text: this.$t('content.blog.lists'),
          active: true
        }
      ],
      blogData: [],
      submitted: false,
      showmodal: false,
      editModal: false,
      startIndex: 0,
      endIndex: 0,
      rows: 100,
      currentPage: 1,
      perPage: 10,
      blogTitle: '',
      blog: {},
      modalTitle: '',
      editType: '',
      categoryData: [],
      image: '',
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
          placeholder: this.$t('common.editorcontent'),
          extraPlugins: [upload.MyCustomUploadAdapterPlugin]
      },
      status: ''
    };
  },
  validations: {
    blog:{
        title: {required},
        categoryId: {required},
        shortDesc: {required}
    },
    editorData: {required}
  },
  created() {

     this.blogLists(this.currentPage-1); 
  },
  methods: {

    categoryLists() {
        getLists('GET', 'blogcategory').then(res => {
            this.categoryData = res.data;
        });
    },

    blogLists(page) {
        getLists('GET', 'blog', page, 'desc').then(res => {
            this.blogData = [];
            res.data.rows.forEach(item => {
                item.status = item.status == 'Active' ? true : false;
                item.createdAt = moment(item.createdAt).format('MMMM,DD YYYY HH:mm:ss');
                this.blogData.push(item);
            })
            this.rows = res.data.count
        });
    },

    setBlogStatus(id, status) {
        var set_status = status == true ? 0 : 1;
        setStatus('GET', 'blog', id, set_status).then();
    },

    createBlog() {
        this.editModal = true;
        this.modalTitle = this.$t('content.blog.add');
        this.editType = 'add';
        this.categoryLists();
        this.blog = {};
        this.editorData = '';
    },
    
    editBlog(id) {
        this.editModal = true;
        this.modalTitle = this.$t('content.blog.edit');
        this.editType = 'edit';

        this.categoryLists();
        
         getData('GET', 'blog', id).then(res => {
            this.id = id;
            this.blog = {
                title: res.data.title,
                categoryId: res.data.categoryId,
                userId: res.data.userId,
                description: res.data.description,
                shortDesc: res.data.shortDesc,
                img: res.data.img,
                metaTitle: res.data.metaTitle,
                metaKeywords: res.data.metaKeywords,
                metaDescription: res.data.metaDescription,
                tags: res.data.tags
            };
            
            this.editorData = this.blog.description;
        });
    },

    fileChange() {
        var that = this;
        var files = document.getElementById("image").files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            that.image = reader.result;
        };
        reader.readAsDataURL(files);
    },

    onPageChange() {
        this.startIndex = (this.currentPage - 1) * this.perPage;
        this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
        this.blogLists(this.currentPage);
    },

    submitData() {
        this.$v.$touch()
        if (this.$v.$invalid) {
              
            this.$swal({
                text: this.$t('content.subscriptions.submitError'),
                confirmButtonText: this.$t('common.ok')
            });

        } else {
          
            this.blog.description = this.editorData;
            this.blog.img = this.image != '' ? this.image.replace(/^data:image\/\w+;base64,/, "") : '';
            
            if (this.editType == 'edit') {
                updateData('PUT', 'blog', this.id, this.blog).then(res => {

                    this.editModal = false;
                    this.$swal({
                          text: res.message,
                          confirmButtonText: this.$t('common.ok')
                    });

                    this.blogLists(this.currentPage - 1);
                });
            } else {
                var user = JSON.parse(localStorage.getItem('user'));
                this.blog.userId = user._id;
                createData('POST', 'blog', this.blog).then(res => {

                    this.editModal = false;
                    this.$swal({
                          text: res.message,
                          confirmButtonText: this.$t('common.ok')
                    });

                    this.blogLists(this.currentPage - 1);
                });
            }
        }
        
    },


    deleteBlog(id) {
        
        this.$swal({
            text: this.$t('common.confirmdelete'),
            confirmButtonText: this.$t('common.ok'),
            cancelButtonText: this.$t('common.cancel'),
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed == true) {

                setStatus('GET', 'blog', id, 2).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.blogLists(this.currentPage - 1);
                });

            }
        }); 
        
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mt-3">

                <div class="row">
                    <div class="col-md-6">
                    <div class="product-title float-left">
                        <button class="btn-add" @click="createBlog('')"><i class="ri-add-line"></i>{{  $t('content.blog.add') }}</button>
                    </div>
                    </div>

                    <div class="col-md-6">
                    <div class="form-inline float-md-right">
                        <div class="search-box ml-2">
                        <div class="position-relative">
                            <input type="text" class="form-control rounded" :placeholder="$t('common.search')" />
                            <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>


              <table
                class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>{{$t('common.id')}}</th>
                    <th>{{$t('common.title')}}</th>
                    <th>{{$t('common.category')}}</th>
                    <th>{{$t('common.writer')}}</th>
                    <th>{{$t('common.createtime')}}</th>
                    <th>{{$t('common.status')}}</th>
                    <th>{{$t('common.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in blogData" :key="index">
                    <td>{{item._id}}</td>
                    <td>{{item.title}}</td>
                    <td>{{item.categoryName}}</td>
                    <td>{{item.writerName}}</td>
                    <td>{{item.createdAt}}</td>
                    <td>

                        <b-form-checkbox v-model="item.status" @change="setBlogStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>

                    </td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        :title="$t('common.edit')"
                        @click="editBlog(item._id)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        :title="$t('common.delete')"
                        @click="deleteBlog(item._id)"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>


              <div class="row justify-content-md-between align-items-md-center">
                <div class="col-xl-7">{{$t("common.showing")}} {{startIndex}} - {{endIndex}} of {{rows}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal v-model="editModal" size="xl" :title="modalTitle" centered>
       <form @submit.prevent="submit">
        
          <div class="tab-pane" id="basic-info">
              <div class="form-group">
                <label for="title">{{$t('common.title')}}</label>
                <input v-model.trim="$v.blog.title.$model" type="text" class="form-control" />
                <span class="error" v-if="!$v.blog.title.required">* {{$t('common.titlerequired')}}</span>
              </div>

              <div class="form-group">
                <label for="category">{{$t('common.category')}}</label>
                <select class="form-control" v-model.trim="$v.blog.categoryId.$model">
                    <option value="">{{ $t('content.category.select') }}</option>
                    <option v-for="(item, index) in categoryData" :key="index" :value="item._id">{{item.categoryName}}</option>
                </select>
                <span class="error" v-if="!$v.blog.categoryId.required">* {{$t('common.categoryrequired')}}</span>
              </div>

              <div class="form-group row-box">
                  <label for="image">{{$t('common.image')}}</label>
                  <input type="file" id="image" accept="image/*" class="form-control" :placeholder="$t('content.gallery.url')" @change="fileChange" />
                  <img :src="blog.img" width="200" style="margin-top: 10px;" />
              </div>

              <div class="form-group row-box">
                  <label for="shortDesc">{{$t('content.blog.shortDesc')}}</label>
                  <textarea class="form-control" v-model.trim="$v.blog.shortDesc.$model"></textarea>
                  <span class="error" v-if="!$v.blog.shortDesc.required">* {{$t('common.shortdescrequired')}}</span>
              </div>

              <div class="form-group row-box">
                  <label for="metaTitle">{{$t('common.metaTitle')}}</label>
                  <input class="form-control" v-model="blog.metaTitle" value="" />
              </div>
              <div class="form-group row-box">
                  <label for="metaKeywords">{{$t('common.metaKeywords')}}</label>
                  <input class="form-control" v-model="blog.metaKeywords" value="" />
              </div>
              <div class="form-group row-box">
                  <label for="metaDescription">{{$t('common.metaDescription')}}</label>
                  <input class="form-control" v-model="blog.metaDescription" value="" />
              </div>

              <div class="form-group" style="margin-top: 20px">
                <label for="productdesc">{{$t('content.products.description')}}</label>
                <ckeditor v-model.trim="$v.editorData.$model" :editor="editor" :config="editorConfig"></ckeditor>
                <span class="error" v-if="!$v.editorData.required">* {{$t('common.descriptionrequired')}}</span>
              </div>
          </div>

      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editModal = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData">
          {{$t('common.submit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>


  </Layout>
</template>